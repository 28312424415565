/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import { List } from "immutable"
import { noop } from "lodash"
import PropTypes from "prop-types"
import * as Shift from "timesheets/models/shift"
import * as Timesheet from "timesheets/models/timesheet"

import Placeholder from "timesheets/components/Placeholder"
import LoginAvatar from "timesheets/components/LoginAvatar"
import ShiftVarianceModal from "timesheets/components/ShiftVarianceModal"
import Allowances from "./Allowances"
import Breakdown from "./Breakdown"
import Chart from "./Chart"
import Config from "./Config"
import Controls from "./Controls"
import Info from "./Info"
import StatusToggle from "./StatusToggle"
import ShiftRating from "./ShiftRating"
import TimeForm from "./TimeForm"
import PlatformFields from "./PlatformFields"
import ShiftChecks from "./ShiftChecks"

import styles from "./styles.module.scss"

export default class ShiftCard extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleMouseLeave = () => this.setState({ isHovered: false })
    this.handleMouseOver = () => this.setState({ isHovered: true })

    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.approveVariance = this.approveVariance.bind(this)

    this.state = { isHovered: false, varianceEnabled: false, shiftSyncing: false }
  }

  renderExportedTip() {
    return Timesheet.isExported(this.props.timesheet) && this.state.isHovered
  }

  submitStatusChange(isApproved) {
    this.props.actions.shiftsSync({
      shift: this.props.shift,
      fields: {
        auto_approved: false,
        status: isApproved ? Shift.Status.Approved : Shift.Status.Pending,
      },
    })
  }

  attachNoteToShift(comment) {
    this.props.actions.addShiftComment({
      shift: this.props.shift,
      fields: {
        comment: comment,
      },
    })
  }

  approveVariance(comment, isApproved) {
    this.attachNoteToShift(comment)
    this.submitStatusChange(isApproved)
    this.setState({ varianceEnabled: false })
  }

  handleStatusChange(isApproved, shiftVariance) {
    if (
      this.props.currentOrganisation.get("shift_variance_approval_required") &&
      shiftVariance.hasVariance &&
      this.props.shift.get("status") === Shift.Status.Pending
    ) {
      this.setState({ varianceEnabled: true, shiftVariance })
    } else {
      this.submitStatusChange(isApproved)
    }
  }

  shouldShowShiftChecks() {
    const shiftId = this.props.shift.get("id").toString()
    const validations = this.props.timesheet.get("validations")
    if (!validations) return false
    return validations.has(shiftId)
  }

  getShiftCheckResults() {
    const shiftId = this.props.shift.get("id").toString()
    const validations = this.props.timesheet.get("validations")
    const shiftValidations = validations.get(shiftId)
    return shiftValidations.get("results").toJS()
  }

  renderApproveArea() {
    if (!this.props.shift.getIn(["permitted_actions", "approve"])) {
      return null
    }

    return (
      <React.Fragment>
        <StatusToggle
          autoApproveShiftsEnabled={this.props.currentOrganisation.get("auto_approve_shifts_enabled")}
          currentUser={this.props.currentUser}
          handleStatusChange={this.handleStatusChange}
          shift={this.props.shift}
          shiftSyncing={this.state.shiftSyncing}
          shiftVarianceApprovalRequired={this.props.currentOrganisation.get("shift_variance_approval_required")}
          showTip={this.renderExportedTip()}
          timesheet={this.props.timesheet}
        />
        {this.props.currentOrganisation.get("shift_ratings_enabled") && (
          <ShiftRating
            actions={this.props.actions}
            readonly={Timesheet.isExported(this.props.timesheet)}
            shift={this.props.shift}
          />
        )}
      </React.Fragment>
    )
  }

  render() {
    if (this.props.shift.get("is_collapsed")) {
      return <Placeholder actions={this.props.actions} shift={this.props.shift} type={this.props.type} />
    }
    return (
      <div
        className={styles.ShiftCard}
        onFocus={noop}
        onMouseLeave={this.handleMouseLeave}
        onMouseOver={this.handleMouseOver}
      >
        <div className={styles.avatar}>
          {" "}
          {/* Hidden on medium down */}
          <LoginAvatar
            photos={this.props.shift
              .get("photos", new List())
              .toJS()
              .filter((item) => !item.url.includes("no_image"))}
            userId={this.props.shift.get("user_id")}
          />
        </div>
        <div className={styles.config}>
          <Info currentUser={this.props.currentUser} shift={this.props.shift} type={this.props.type} />
          <Config
            actions={this.props.actions}
            currentOrganisation={this.props.currentOrganisation}
            hovered={this.state.isHovered}
            shift={this.props.shift}
            timesheet={this.props.timesheet}
          />
        </div>
        <div className={styles.body}>
          <span>
            <Chart
              colors={this.props.colors}
              currentUser={this.props.currentUser}
              setIsSyncing={(isSyncing) => this.setState({ shiftSyncing: isSyncing })}
              shift={this.props.shift}
            />
            <TimeForm
              actions={this.props.actions}
              currentOrganisation={this.props.currentOrganisation}
              currentUser={this.props.currentUser}
              shift={this.props.shift}
              timesheet={this.props.timesheet}
            />
          </span>
          <Allowances
            actions={this.props.actions}
            requiresAveraging={this.props.requiresAveraging}
            shift={this.props.shift}
            timesheet={this.props.timesheet}
          />
          {this.shouldShowShiftChecks() && (
            <ShiftChecks loading={this.state.shiftSyncing} results={this.getShiftCheckResults()} />
          )}
          <PlatformFields
            appOpenPlatformModal={this.props.actions.appOpenPlatformModal}
            platformModalShiftId={this.props.platformModalShiftId}
            shift={this.props.shift}
            showPlatform={this.props.currentOrganisation.get("show_platform")}
            timesheet={this.props.timesheet}
          />
        </div>
        <div className={styles.info}>
          <Breakdown
            currentOrganisation={this.props.currentOrganisation}
            isMultipleShift={Shift.isMultipleShift(this.props.shift, this.props.shifts)}
            shift={this.props.shift}
          />
          {this.renderApproveArea()}
        </div>
        <div className={styles.controls}>
          <Controls
            actions={this.props.actions}
            currentUser={this.props.currentUser}
            handleCollapseCard={this.handleCollapseCard}
            shift={this.props.shift}
            timesheet={this.props.timesheet}
          />
        </div>
        {this.state.varianceEnabled && (
          <ShiftVarianceModal
            canSeeCosts={this.props.shift.getIn(["permitted_actions", "see_costs"])}
            closeModal={() => {
              this.setState({ varianceEnabled: false })
            }}
            costVariance={this.state.shiftVariance.costVariance}
            open={this.state.varianceEnabled}
            submit={this.approveVariance}
            timeVariance={this.state.shiftVariance.timeVariance}
          />
        )}
      </div>
    )
  }
}

ShiftCard.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  colors: PropTypes.object.isRequired,
  currentOrganisation: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  platformModalShiftId: PropTypes.number,
  requiresAveraging: PropTypes.bool,
  shift: PropTypes.object.isRequired,
  shifts: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["DATE_CARD", "USER_CARD"]).isRequired,
}

ShiftCard.defaultProps = {
  platformModalShiftId: undefined,
  requiresAveraging: false,
}
