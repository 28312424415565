/**
 * @flow
 */
import * as React from "react"
import { omit } from "lodash"
import moment from "moment"
import { t as globalT } from "helpers/i18n"
import ModalView, { Footer, FooterLeft, FooterRight } from "components/Modal"
import Button from "components/Button"
import Text from "components/Text"
import Failure from "time_off/components/Failure"
import type {
  DailyBreakdown,
  BreakdownDefaults,
  NonSubmittableBreakdownDefaults,
  ShiftSummary,
  Event,
  LeaveInfo,
  Department,
} from "../types"
import Formatter from "./helpers/formatters"
import {
  START_TIME,
  FINISH_TIME,
  HOURS,
  ALL_DAY,
  ZERO_HOURS,
  INVALID_TIME,
  TIME_FORMATS,
  DATE,
  ID,
} from "./helpers/constants"
import Validators from "./helpers/validators"
import DailyBreakdownRow from "./components/DailyBreakdownRow"
import BreakdownRowTemplate from "./components/BreakdownRowTemplate"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.edit_daily_breakdown_modal.${key}`, opts)

const compareShiftSummaries = (a: ShiftSummary, b: ShiftSummary) => {
  if (a[DATE] < b[DATE]) {
    return -1
  } else if (a[DATE] > b[DATE]) {
    return 1
  } else if (a[START_TIME] < b[START_TIME]) {
    return -1
  } else if (a[START_TIME] > b[START_TIME]) {
    return 1
  } else {
    return 0
  }
}

type Props = {|
  +defaults: ?BreakdownDefaults,
  +departments: Array<Department>,
  +onCancel: () => void,
  +onSuccess: (defaults: $Diff<BreakdownDefaults, NonSubmittableBreakdownDefaults>) => void,
  +open: boolean,
|}

type State = {|
  ...LeaveInfo,
|}

export default class EditDailyBreakdownModal extends React.PureComponent<Props, State> {
  cost_request_interval: IntervalID

  UNSAFE_componentWillReceiveProps({ defaults }: Props) {
    this.setState({
      ...omit(defaults, "initialDailyBreakdown"),
      daily_breakdown: defaults != null ? this.parseBreakdown(defaults.daily_breakdown) : undefined,
    })
  }

  onSubmit: () => void = (): void => {
    if (this.state.errors != null && Object.keys(this.state.errors).length === 0) {
      const submitObject = {
        can_edit: this.state.can_edit,
        daily_breakdown: this.state.daily_breakdown,
        errors: this.state.errors,
        hours: this.state.hours,
        id: this.state.id,
        pending_ppt_acceptance_dates: this.props.defaults?.pending_ppt_acceptance_dates || {},
        status: this.state.status,
        user: this.props.defaults?.user,
        userId: this.state.userId,
      }
      this.props.onSuccess(submitObject)
    }
  }

  parseBreakdown: (dailyBreakdown: DailyBreakdown) => DailyBreakdown = (
    dailyBreakdown: DailyBreakdown
  ): DailyBreakdown =>
    dailyBreakdown.map((shiftBreakdown: ShiftSummary) => ({
      ...shiftBreakdown,
      [START_TIME]: Formatter.formatTime(shiftBreakdown[START_TIME]),
      [FINISH_TIME]: Formatter.formatTime(shiftBreakdown[FINISH_TIME]),
      [HOURS]: shiftBreakdown[HOURS],
      [ALL_DAY]: shiftBreakdown[ALL_DAY],
    }))

  validateBreakdown: () => void = () => {
    const errors = this.state.daily_breakdown
      .map((summary) => Validators.validateBreakdownRow(summary, summary.date))
      .filter((el) => el != null)
    const newErrors = Object.assign({}, ...errors)

    this.setState({ errors: newErrors }, this.onSubmit)
  }

  handleAlldayChange: (event: Event) => void = (event: Event): void => {
    const isAllDay = event.target.value
    const idToChange = event.target.id
    const summaryToChange = this.state.daily_breakdown.find((summary) => summary[ID] === idToChange)

    if (summaryToChange == null) {
      return
    }

    const { start_time, finish_time, date } = summaryToChange
    const breakdownStart = moment(`${date} ${start_time || "09:00"}`)
    const breakdownFinish = moment(`${date} ${finish_time || "17:00"}`)
    let breakdownHours = isAllDay
      ? window.LeaveRequest.defaults.leave_length
      : moment.duration(breakdownFinish.diff(breakdownStart)).asHours()
    breakdownHours =
      breakdownHours < 0 ? Formatter.getTimeDiffOvernight(breakdownStart, breakdownFinish).asHours() : breakdownHours

    const newShiftSummary = {
      ...summaryToChange,
      [ALL_DAY]: JSON.parse(String(event.target.value)),
      hours: breakdownHours,
      start_time: isAllDay ? "" : summaryToChange.start_time,
      finish_time: isAllDay ? "" : summaryToChange.finish_time,
    }

    const newBreakdown = [
      ...this.state.daily_breakdown.filter((summary) => summary.id !== newShiftSummary.id),
      newShiftSummary,
    ]

    this.setState({
      daily_breakdown: newBreakdown,
      errors: {},
      hours: Formatter.getTotalHours(newBreakdown),
    })
  }

  handleInputChange: (event: Event) => void = (event: Event) => {
    const value = String(event.target.value)
    const idToChange = event.target.id
    const fieldToChange = event.target.key

    const previousBreakdown = this.state.previous_daily_breakdown || this.state.daily_breakdown
    const summaryToChange = this.state.daily_breakdown.find((summary) => summary[ID] === idToChange)
    if (summaryToChange == null) {
      return
    }
    const previousValue = summaryToChange[fieldToChange]
    let newValue = ""

    if (fieldToChange === HOURS) {
      const floatNumberMatcher = /^\d*\.?\d*$/
      newValue = floatNumberMatcher.test(value) ? value : previousValue
    } else if (fieldToChange === START_TIME || fieldToChange === FINISH_TIME) {
      const timeFormatMatcher = /^(?!(a|p))(?!m)[0-9]?[0-9]?:?[0-5]?[0-9]?(a|p)?m?$/
      newValue = timeFormatMatcher.test(value) ? value : previousValue
    } else {
      newValue = value
    }

    const newShiftSummary = {
      ...summaryToChange,
      // $FlowFixMe Setting using computed property, but it's ok.
      [fieldToChange]: newValue,
    }

    const newBreakdown = [
      ...this.state.daily_breakdown.filter((summary) => summary.id !== newShiftSummary.id),
      newShiftSummary,
    ]

    this.setState({
      daily_breakdown: newBreakdown,
      hours: Formatter.getTotalHours(newBreakdown),
      previous_daily_breakdown: previousBreakdown,
    })
  }

  handleHoursBlur: (event: Event) => void = (event: Event): void => {
    const idToChange = event.target.id
    const fieldToChange = event.target.key
    const summaryToChange = this.state.daily_breakdown.find((summary) => summary[ID] === idToChange)

    const newShiftSummary = {
      ...summaryToChange,
      // $FlowFixMe Setting using computed property, but it's ok.
      [fieldToChange]: !isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value) : ZERO_HOURS,
      filled_from: Formatter.dirtifyFilledFrom(summaryToChange?.filled_from || "other"),
    }

    const initialSummary = (this.props.defaults?.initialDailyBreakdown || []).find(
      (ss) => ss[ID] === newShiftSummary[ID]
    )

    if (initialSummary && Validators.hasBeenModifiedFrom(newShiftSummary, initialSummary)) {
      newShiftSummary["filled_from"] = Formatter.cleanFilledFrom(newShiftSummary["filled_from"])
    }

    this.setState({
      daily_breakdown: [
        ...this.state.daily_breakdown.filter((summary) => summary.id !== newShiftSummary.id),
        newShiftSummary,
      ],
      errors: ({}: $Shape<{||}>),
    })
  }

  handleTimeBlur: (event: Event) => void = (event: Event): void => {
    const value = String(event.target.value)
    const idToChange = event.target.id
    const fieldToChange = event.target.key
    const otherTime = fieldToChange === START_TIME ? FINISH_TIME : START_TIME

    const breakdown = this.state.previous_daily_breakdown || this.state.daily_breakdown
    const summaryToChange = breakdown.find((summary) => summary[ID] === idToChange)
    if (summaryToChange == null) {
      return
    }
    if (value === summaryToChange[fieldToChange].toString()) {
      return
    }
    const duration = Formatter.getTimeDiff(summaryToChange[otherTime], moment(value, TIME_FORMATS))

    let newValue = null
    if (value !== "0") {
      newValue = Formatter.formatTime(value)
    }

    const newShiftSummary: ShiftSummary = {
      ...summaryToChange,
      // $FlowFixMe Setting using computed property, but it's ok.
      [fieldToChange]: newValue,
      filled_from: Formatter.dirtifyFilledFrom(summaryToChange?.filled_from),
    }

    if (!duration.isValid()) {
      newShiftSummary[HOURS] = ZERO_HOURS
      return this.setState({
        errors: newValue === INVALID_TIME ? { [idToChange]: fieldToChange } : ({}: $Shape<{||}>),
        daily_breakdown: [
          ...this.state.daily_breakdown.filter((summary) => summary.id !== newShiftSummary.id),
          newShiftSummary,
        ],
        previous_daily_breakdown: null,
      })
    }

    if (fieldToChange === START_TIME) {
      if (duration.asMilliseconds() >= 0) {
        newShiftSummary[HOURS] = duration.asHours()
      } else {
        const start = moment(value, TIME_FORMATS)
        const finish = moment(summaryToChange[otherTime], TIME_FORMATS)
        newShiftSummary[HOURS] = Formatter.getTimeDiffOvernight(start, finish).asHours()
      }
    } else {
      if (duration.asMilliseconds() <= 0) {
        newShiftSummary[HOURS] = Math.abs(duration.asHours())
      } else {
        const start = moment(summaryToChange[otherTime], TIME_FORMATS)
        const finish = moment(value, TIME_FORMATS)
        newShiftSummary[HOURS] = Formatter.getTimeDiffOvernight(start, finish).asHours()
      }
    }

    const initialSummary = (this.props.defaults?.initialDailyBreakdown || []).find(
      (ss) => ss[ID] === newShiftSummary[ID]
    )

    if (initialSummary != null && Validators.hasBeenModifiedFrom(newShiftSummary, initialSummary)) {
      newShiftSummary["filled_from"] = Formatter.cleanFilledFrom(newShiftSummary["filled_from"])
    }

    const newBreakdown = [
      ...this.state.daily_breakdown.filter((summary) => summary.id !== newShiftSummary.id),
      newShiftSummary,
    ]

    this.setState({
      daily_breakdown: newBreakdown,
      errors: Validators.validateBreakdownRow(newShiftSummary, idToChange),
      hours: Formatter.getTotalHours(newBreakdown),
      previous_daily_breakdown: null,
    })
  }

  getUserId: () => number | string = (): number | string => {
    /*
     * when state is not empty and it contains a user_id it means
     * the leave is already created and we are now editing the breakdown
     * when the props default is present it contains a userId it means
     * the leave is still being applied for and we are showing the
     * breakdown using the userId props on the create leave modal
     * */
    if (this.state != null && this.state.user_id) {
      return this.state.user_id
    } else if (this.props.defaults != null && this.props.defaults.userId) {
      return this.props.defaults.userId
    } else {
      return ""
    }
  }

  getDefaultTeamId: (shift_summary: ShiftSummary) => string = (shift_summary: ShiftSummary) => {
    const user = this.props.defaults?.user

    if (user && typeof user === "object") {
      return shift_summary.department_id || user.default_tag?.toString() || ""
    }
    return shift_summary.department_id
  }

  handleClearingRowData: (id: string) => void = (id: string) => {
    const maybeSummaryToClear: ?ShiftSummary = this.state.daily_breakdown.find((summary) => summary[ID] === id)
    if (maybeSummaryToClear == null) {
      return
    }
    const summaryToClear: ShiftSummary = maybeSummaryToClear
    const newShiftSummary: ShiftSummary = {
      ...summaryToClear,
      [HOURS]: 0,
      [ALL_DAY]: true,
    }

    const newBreakdown = [
      ...this.state.daily_breakdown.filter((summary) => summary.id !== newShiftSummary.id),
      newShiftSummary,
    ]

    this.setState({
      daily_breakdown: newBreakdown,
      hours: Formatter.getTotalHours(newBreakdown),
    })
  }

  render(): React.Node {
    // $FlowFixMe dividing by 1 here to strip trailing 0s from the hours decimal.
    const displayHours = this.state !== null && this.state.hours !== null ? this.state.hours / 1 : ""

    // If the shift attach never started, the data will always be up to date
    const shiftDataIsUpToDate =
      this.props.defaults == null ||
      this.props.defaults.shift_attach_last_started_at == null ||
      (this.props.defaults.shift_attach_last_finished_at != null &&
        this.props.defaults.shift_attach_last_finished_at >= this.props.defaults.shift_attach_last_started_at)

    return (
      <ModalView fixedMaxHeight onExit={this.props.onCancel} open={this.props.open} size="l">
        <div className={styles.headerContainer}>
          <p className={styles.header}>
            {this.props.defaults?.status != null ? t(`${this.props.defaults.status}_title`) : t("default_title")}
          </p>
        </div>
        {this.state?.original_hours != null && (
          <div className={styles.subHeaderContainer}>
            <p className={styles.subHeader}>{t("employee_requested_hours", { hours: this.state.original_hours })}</p>
          </div>
        )}
        {this.state?.status === "approved" &&
          (this.props.defaults?.initialDailyBreakdown || []).some((ss) => ss.timesheet_on_this_day_is_exported) && (
            <div className={styles.subHeaderContainer}>
              <p className={styles.subHeader}>{t("some_days_are_exported")}</p>
            </div>
          )}
        {!shiftDataIsUpToDate ? (
          <Failure backAction={null} setRef={() => {}} text={t("approving_leave_request_now")} />
        ) : (
          <div className={styles.breakdownContainer}>
            <div className={styles.breakdownBody}>
              <BreakdownRowTemplate
                dateColString={t("date")}
                filledFromColString={
                  this.state?.daily_breakdown != null &&
                  this.state.daily_breakdown.some((ss) =>
                    ["roster", "unpublished_roster", "regular_hours"].includes(ss["filled_from"])
                  )
                    ? t("filled_from")
                    : ""
                }
                hoursColString={t("hours")}
                loadingTeams={false}
                teamColString={t("team")}
                timeColString={t("time")}
              />
              {this.state?.daily_breakdown != null &&
                this.state.daily_breakdown
                  .sort(compareShiftSummaries)
                  .map((shift_summary: ShiftSummary, i: number) => (
                    <DailyBreakdownRow
                      all_day={shift_summary.all_day}
                      can_edit={
                        this.props.defaults != null &&
                        this.props.defaults.can_edit &&
                        this.props.defaults.status !== "rejected"
                      }
                      date={shift_summary.date}
                      departmentId={this.getDefaultTeamId(shift_summary)}
                      departments={this.props.departments}
                      error={this.state.errors != null ? this.state.errors[shift_summary.date] : ""}
                      filled_from={shift_summary.filled_from}
                      finish_time={shift_summary.finish_time}
                      handleAlldayChange={this.handleAlldayChange}
                      handleClearingRowData={this.handleClearingRowData}
                      handleHoursBlur={this.handleHoursBlur}
                      handleInputChange={this.handleInputChange}
                      handleTimeBlur={this.handleTimeBlur}
                      hours={shift_summary.hours}
                      id={shift_summary.id}
                      initialShiftSummary={
                        this.props.defaults?.initialDailyBreakdown != null
                          ? this.props.defaults.initialDailyBreakdown[i]
                          : shift_summary
                      }
                      key={shift_summary.id}
                      pendingPptAcceptance={
                        this.props.defaults != null
                          ? (this.props.defaults.pending_ppt_acceptance_dates || {})[shift_summary.date]
                          : null
                      }
                      start_time={shift_summary.start_time}
                      status={this.state.status}
                      user={this.props.defaults?.user}
                      user_id={this.getUserId()}
                    />
                  ))}
              <BreakdownRowTemplate
                dateColString=""
                filledFromColString=""
                hoursColString={t("total", {
                  total: this.state != null ? Formatter.getTotalHours(this.state.daily_breakdown) : ZERO_HOURS,
                })}
                isSummary
                loadingTeams={false}
                teamColString={
                  this.state?.original_hours != null && this.state.original_hours !== displayHours
                    ? this.state?.is_leave_averaging_leave_type
                      ? t("averaging_warning")
                      : t("hours_warning")
                    : ""
                }
                timeColString=""
              />
            </div>
          </div>
        )}
        {this.props.defaults?.status === "pending" ? (
          <Text color="warning" padding="0 0 0.75rem 0" type="small">
            {t("save_warning")}
          </Text>
        ) : null}
        <Footer>
          <FooterLeft>
            <Button label={t("cancel")} onClick={this.props.onCancel} />
          </FooterLeft>
          <FooterRight>
            {this.props.defaults != null && this.props.defaults.can_edit && (
              <Button
                disabled={
                  !shiftDataIsUpToDate || (this.state?.errors != null && Object.keys(this.state.errors).length > 0)
                }
                label={this.props.defaults?.status === "approved" ? t("save") : t("done")}
                onClick={this.validateBreakdown}
                type="action"
              />
            )}
          </FooterRight>
        </Footer>
      </ModalView>
    )
  }
}
