/* @flow */

import * as React from "react"
import { t as globalT } from "helpers/i18n"
import Icon from "../Icon"
import styles from "./styles.module.scss"

const t = (key, props) => globalT(`js.generic_components.modal.${key}`, props)

export default function ModalBack(props: { onClick: () => void }): React.Element<"button"> {
  return (
    <button aria-label={t("back_aria")} className={styles.modal_back} onClick={props.onClick}>
      <Icon color="black" type="chevron-left" />
    </button>
  )
}
