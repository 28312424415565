/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import moment from "moment"

import * as Shift from "timesheets/models/shift"
import * as Timesheet from "timesheets/models/timesheet"
import * as User from "timesheets/models/user"

import TimeInput from "./TimeInput"
import BreakTotal from "./BreakTotal"
import PrintOnlyBreakTimes from "./PrintOnlyBreakTimes"

import styles from "./styles.module.scss"

export default class TimeForm extends React.PureComponent {
  static isRequired(dependantField, rosteredField) {
    // rules:
    return (
      (rosteredField.isValid() && rosteredField.isBefore(moment())) || //   - if rostered time is past show required.
      (!rosteredField.isValid() && dependantField.isValid())
    ) //   - if no rostered time and dependent time
  } //     is valid show required.

  //     dependent should be opposite to field being
  //     checked, eg. if checking finish, dependent
  //     field would be start (so we need a finish
  //     if we have a start)

  static isDisabled({ currentUser, shift, timesheet }) {
    return (
      !shift.getIn(["permitted_actions", "update"]) ||
      Shift.isApproved(shift) ||
      Timesheet.isExported(timesheet) ||
      Shift.limberWorker(shift)
    )
  }

  static isFieldDisabled(field, { currentUser, shift }) {
    // disable if time is not empty, time is approved by manager, and user cannot edit others
    return (
      !Number.isNaN(shift.get(field)._i) &&
      !!shift.get(`${field}_confirmed`) &&
      !User.canEditConfirmedTimes(currentUser)
    )
  }

  constructor(props) {
    super(props)

    this.state = {
      isDisabled: TimeForm.isDisabled(props),
      showBreakPopup: false,
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.shift !== this.props.shift || props.timesheet !== this.props.timesheet) {
      const newState = { isDisabled: TimeForm.isDisabled(props) }
      // if form is now disabled (eg. because shift was approved), we must hide the break popup
      // but if form is still enabled we don't make changes (don't set to true otherwise we'd force open the popup)
      if (newState.isDisabled) {
        newState.showBreakPopup = false
      }
      this.setState(newState)
    }
  }

  openBreaksPopup = () => {
    this.setState({ showBreakPopup: true })
  }

  closeBreaksPopup = () => {
    this.setState({ showBreakPopup: false })
  }

  render() {
    return (
      <div className={styles.TimeForm}>
        <div className={styles.clockedTimeInputs}>
          <TimeInput
            actions={this.props.actions}
            clocked={this.props.shift.get("clocked_start")}
            confirmed={this.props.shift.get("start_confirmed")}
            currentUser={this.props.currentUser}
            date={this.props.shift.get("date")}
            disabled={this.state.isDisabled || TimeForm.isFieldDisabled("start", this.props)}
            error={this.props.shift.get("start_error")}
            field="start"
            required={TimeForm.isRequired(this.props.shift.get("finish"), this.props.shift.get("rostered_start"))}
            rostered={this.props.shift.get("rostered_start")}
            shiftOrBreak={this.props.shift}
          />
          <TimeInput
            actions={this.props.actions}
            clocked={this.props.shift.get("clocked_finish")}
            confirmed={this.props.shift.get("finish_confirmed")}
            currentUser={this.props.currentUser}
            date={this.props.shift.get("date")}
            disabled={this.state.isDisabled || TimeForm.isFieldDisabled("finish", this.props)}
            error={this.props.shift.get("finish_error")}
            field="finish"
            required={TimeForm.isRequired(this.props.shift.get("start"), this.props.shift.get("rostered_end"))}
            rostered={this.props.shift.get("rostered_end")}
            shiftOrBreak={this.props.shift}
          />
        </div>

        <PrintOnlyBreakTimes currentUser={this.props.currentUser} shift={this.props.shift} />

        <BreakTotal
          actions={this.props.actions}
          breakPopoverVisible={this.state.showBreakPopup}
          currentUser={this.props.currentUser}
          disabled={this.state.isDisabled}
          hidePopover={this.closeBreaksPopup}
          openPopover={this.openBreaksPopup}
          shift={this.props.shift}
        />
      </div>
    )
  }
}

TimeForm.propTypes = {
  actions: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  shift: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
}
