/**
 * @flow
 */
import * as React from "react"
import styles from "./styles.module.scss"

type Props = {|
  +required?: boolean,
  +text: string,
|}

export default class Label extends React.PureComponent<Props> {
  static defaultProps: {| required: boolean |} = { required: false }

  render(): React.Element<"div"> {
    return (
      <div className={styles.label}>
        <span>{this.props.text}</span>
        {this.props.required && <span className={styles.required}>*</span>}
      </div>
    )
  }
}
