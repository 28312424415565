/**
 * @flow
 */

import * as React from "react"
import { t as globalT } from "helpers/i18n"
import { RadioButton, RadioList } from "components/Radio"
import Label from "../Label"

const t = (key, opts) => globalT(`js.leave.modal.request_status.${key}`, opts)

type Props = {
  onChange: (autoApprove: boolean) => void,
  value: boolean,
}

export function RequestStatusSection(props: Props): React.Element<"div"> {
  return (
    <div>
      <Label text={t("title")} />
      <RadioList onClick={props.onChange} selectedValue={props.value}>
        <RadioButton label={t("create_approved")} value />
        <RadioButton label={t("create_pending")} value={false} />
      </RadioList>
    </div>
  )
}
