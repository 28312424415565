/**
 * @flow
 */
import * as React from "react"
import Loader from "components/Loader"
import Text from "components/Text"
import styles from "../styles.module.scss"

type Props = {|
  dateColString: string,
  filledFromColString: string,
  hoursColString: string,
  isSummary?: boolean,
  loadingTeams: boolean,
  teamColString: string,
  timeColString: string,
|}

const BreakdownRowTemplate = ({
  dateColString,
  filledFromColString,
  timeColString,
  hoursColString,
  isSummary,
  teamColString,
  loadingTeams,
}: Props): React.Element<"div"> => (
  <div className={isSummary ? styles.dailyBreakdownRowSummary : styles.dailyBreakdownRow}>
    <div className={styles.filledFromSpacer}>
      <Text>{filledFromColString}</Text>
    </div>
    <div className={styles.dateSpacer}>
      <Text>{dateColString}</Text>
    </div>
    <div className={styles.timeSpacer}>
      <Text>{timeColString}</Text>
    </div>
    <div className={styles.hoursSpacer}>
      <Text>{hoursColString}</Text>
    </div>
    <div className={styles.teamSpacer}>
      {isSummary ? (
        <Text color="danger" type="tiny">
          {teamColString}
        </Text>
      ) : (
        <Text>{teamColString}</Text>
      )}
      <div className={styles.teamLoaderWrapper}>{loadingTeams && <Loader size="xs" />}</div>
    </div>
  </div>
)

BreakdownRowTemplate.defaultProps = { isSummary: false }

export default BreakdownRowTemplate
