/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./styles.module.scss"

export default function Image(props) {
  const wrapperStyle = cn(styles.imageWrapper, { [styles.imageWrapperLarge]: props.large })
  const imageStyle = cn(styles.image, { [styles.imageLarge]: props.large })

  return (
    <div className={wrapperStyle}>
      <img alt={props.alt} className={imageStyle} src={props.source} />
    </div>
  )
}

Image.propTypes = {
  source: PropTypes.string.isRequired,
  alt: PropTypes.string,
  large: PropTypes.bool,
}

Image.defaultProps = { alt: "", large: false }
