/**
 * @flow
 */

import { isNaN } from "lodash"
import * as NumberHelpers from "helpers/number_helpers"
import type { CurrentUser, LeaveBalance } from "time_off/Modal/types"

/**
 * Converts a given date to a string
 * @param {moment$Moment} date the date to pass in
 * @returns a formatted string version of the given date, else null
 */
export const dateToString = (date: moment$Moment): ?string =>
  date && date.isValid() ? date.format("YYYY-MM-DD") : null

/**
 * Gets a leave balance from the users available leave types
 * @param {Array<LeaveBalance>} leaveBalances the users available leave types
 * @param {string} leaveType the requested leave type
 * @returns a single leave balance that matches the leaveType, else undefined
 */
export const getLeaveBalance = (leaveBalances: Array<LeaveBalance>, leaveType: ?string): ?LeaveBalance => {
  if (!leaveBalances || !leaveType) {
    return null
  }

  return leaveBalances.find((lb) => lb.leaveType.isAnAccruingLeaveType && lb.leaveType.name === leaveType)
}

/**
 * Get's the leave prediction from a given LeaveBalance and gives it in hours or days
 * @param {LeaveBalance} leaveBalance the leave balance to get the prediction from
 * @param {string} leaveDate the date the prediction is for
 * @param {boolean} inDays displays the prediction in days if true
 * @returns a leave prediction for the given date in hours or days
 */
export const getLeaveBalancePrediction = (
  leaveBalance: LeaveBalance,
  leaveDate: ?string,
  inDays: boolean = false
): ?number => {
  if (!leaveBalance || !leaveDate) {
    return null
  }

  if (inDays) {
    return leaveBalance.predictions[leaveDate] / leaveBalance.leaveType.defaultLeaveLength
  }

  return leaveBalance.predictions[leaveDate]
}

/**
 * Converts a value to hours or days
 * @param {number} leaveValue the value to convert
 * @param {number} defaultLeaveLength the default leave length of the selected leave type
 * @param {boolean} inDays boolean value to select which conversion method to use
 * @returns a converted form of the original leaveValue depending on the value of inDays, else null
 */
export const convertValueToHoursOrDays = (
  leaveValue: ?number,
  defaultLeaveLength: number,
  inDays: boolean
): ?number => {
  if (!leaveValue) {
    return null
  }

  switch (inDays) {
    case false:
      return leaveValue * defaultLeaveLength

    case true:
      return leaveValue / defaultLeaveLength

    default:
      null
  }
}

/**
 * Determines whether to show the leave accrual prediction
 * @param {number} leaveBalancePrediction the prediction to show
 * @param {number} currentLeaveBalance the users current leave balance
 * @returns true if the currentLeaveBalance and leaveBalancePrediction are not equal, else false
 */
export const showPrediction = (leaveBalancePrediction: ?number, currentLeaveBalance: ?number): boolean => {
  if (
    leaveBalancePrediction == null ||
    isNaN(leaveBalancePrediction) ||
    NumberHelpers.roundWithDecimals(leaveBalancePrediction, 2) ===
      NumberHelpers.roundWithDecimals(currentLeaveBalance || 0, 2)
  ) {
    return false
  }

  return true
}

/**
 * Determines which message to render to the user
 * @param {boolean} viewInDays boolean value to determine whether to show message as days or hours
 * @param {boolean} isMe boolean value to determine if leave requester is the current user or not
 * @returns the corresponding YAML key
 */
export const selectAccrualPredictionMessage = (viewInDays: boolean, isMe: boolean): string => {
  switch (viewInDays) {
    case true:
      if (!isMe) {
        return "leave_balance_label_other_days"
      } else {
        return "leave_balance_label_me_days"
      }

    case false:
      if (!isMe) {
        return "leave_balance_label_other"
      } else {
        return "leave_balance_label_me"
      }

    default:
      return "leave_balance_label_me"
  }
}

export const canApproveLeaveRequestForUser = (currentUser: CurrentUser, userId: ?string): boolean => {
  if (!userId) {
    return false
  }

  if (!currentUser.isManager) {
    return false
  }

  if (userId !== currentUser.id) {
    return true
  }

  return currentUser.canApproveOwnLeaveRequest || false
}
