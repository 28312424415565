/**
 * @flow
 */
import * as React from "react"
import moment from "moment"
import type {
  CurrentUser,
  Time,
  LeaveUser,
  LeavesListItem,
  DailyBreakdown,
  FillFromRosterStrategy,
  PptSchedule,
  ShiftsListItem,
} from "time_off/Modal/types"
import DetailSection from "../DetailSection"
import FileSection from "../FileSection"
import ReasonSection from "../ReasonSection"
import TimeSection from "../TimeSection"
import UserSection from "../UserSection"
import { RequestStatusSection } from "../RequestStatusSection"
import styles from "./styles.module.scss"

type Props = {
  allDay: boolean,
  canSelfApprove: boolean,
  currentLeaveBalance: number,
  currentUser: CurrentUser,
  daily_breakdown: DailyBreakdown,
  days: ?string,
  departmentId: ?string,
  endDate: moment,
  endTime: Time,
  fallbackLeaveTypeName: ?string,
  fetchingDailyBreakdown: boolean,
  fetchingLeaveAppliesOnDates: boolean,
  fillFromRosterStrategy: FillFromRosterStrategy,
  finalLeaveBalance: number,
  forUser: boolean,
  hours: string,
  initialDailyBreakdown: DailyBreakdown,
  isLeaveAveragingLeaveType: boolean,
  leaveAppliesOnDates: { [string]: boolean },
  leavesList: Array<LeavesListItem>,
  leaveTypeName: ?string,
  onAllDayChange: (allDay: boolean) => void,
  onDepartmentIdChange: (departmentId: string) => void,
  onEndDateChange: (endDate: moment) => void,
  onEndTimeChange: (endTime: Time) => void,
  onFallbackLeaveTypeChange: (fallbackLeaveType: string) => void,
  onFillFromRosterStrategyChange: (strategy: FillFromRosterStrategy) => void,
  onHoursChange: (hours: string) => void,
  onLeaveTypeChange: (leaveType: string) => void,
  onReasonChange: (reason: string) => void,
  onSaveDailyBreakdown: (DailyBreakdown) => void,
  onSelfApproveChange: (selfApprove: boolean) => void,
  onShiftOverlapChange: (shiftOverlap: boolean) => void,
  onStartDateChange: (startDate: moment) => void,
  onStartTimeChange: (startTime: Time) => void,
  onUserIdChange: (userId: string) => void,
  onVerificationChange: (verification: ?File) => void,
  pendingPptAcceptanceDates: { [date: string]: Array<PptSchedule> },
  reason: string,
  selfApprove: boolean,
  setRef: (node: ?HTMLDivElement) => void,
  shiftsList: Array<ShiftsListItem>,
  startDate: moment,
  startTime: Time,
  userId: ?string,
  users: Array<LeaveUser>,
  verification: ?File,
  verificationError: ?string,
  verificationTypes: Array<string>,
  viewInDays: boolean,
}

export default function Form(props: Props): React.Node {
  return (
    <div className={styles.scroll} ref={props.setRef}>
      <div className={styles.form}>
        <UserSection
          editable={!props.forUser && props.users.length !== 1}
          onUserIdChange={props.onUserIdChange}
          userId={props.userId}
          users={props.users}
        />
        <DetailSection
          currentUser={props.currentUser}
          daily_breakdown={props.daily_breakdown}
          departmentId={props.departmentId}
          fallbackLeaveTypeName={props.fallbackLeaveTypeName}
          leaveTypeName={props.leaveTypeName}
          onDepartmentIdChange={props.onDepartmentIdChange}
          onFallbackLeaveTypeChange={props.onFallbackLeaveTypeChange}
          onLeaveTypeChange={props.onLeaveTypeChange}
          startDate={props.startDate}
          userId={props.userId}
          users={props.users}
        />
        <TimeSection
          allDay={props.allDay}
          currentLeaveBalance={props.currentLeaveBalance}
          currentUser={props.currentUser}
          daily_breakdown={props.daily_breakdown}
          days={props.days}
          endDate={props.endDate}
          endTime={props.endTime}
          fallbackLeaveTypeName={props.fallbackLeaveTypeName}
          fetchingDailyBreakdown={props.fetchingDailyBreakdown}
          fetchingLeaveAppliesOnDates={props.fetchingLeaveAppliesOnDates}
          fillFromRosterStrategy={props.fillFromRosterStrategy}
          finalLeaveBalance={props.finalLeaveBalance}
          hours={props.hours}
          initialDailyBreakdown={props.initialDailyBreakdown}
          isLeaveAveragingLeaveType={props.isLeaveAveragingLeaveType}
          leaveAppliesOnDates={props.leaveAppliesOnDates}
          leavesList={props.leavesList}
          leaveTypeName={props.leaveTypeName || ""}
          onAllDayChange={props.onAllDayChange}
          onEndDateChange={props.onEndDateChange}
          onEndTimeChange={props.onEndTimeChange}
          onFillFromRosterStrategyChange={props.onFillFromRosterStrategyChange}
          onHoursChange={props.onHoursChange}
          onSaveDailyBreakdown={props.onSaveDailyBreakdown}
          onShiftOverlapChange={props.onShiftOverlapChange}
          onStartDateChange={props.onStartDateChange}
          onStartTimeChange={props.onStartTimeChange}
          pendingPptAcceptanceDates={props.pendingPptAcceptanceDates}
          shiftsList={props.shiftsList}
          startDate={props.startDate}
          startTime={props.startTime}
          userId={props.userId}
          users={props.users}
          viewInDays={props.viewInDays}
        />
        <ReasonSection onChange={props.onReasonChange} value={props.reason} />
        <FileSection
          error={props.verificationError}
          onChange={props.onVerificationChange}
          value={props.verification}
          verificationTypes={props.verificationTypes}
        />
        {props.canSelfApprove && (
          <RequestStatusSection onChange={props.onSelfApproveChange} value={props.selfApprove} />
        )}
      </div>
    </div>
  )
}
