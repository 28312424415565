/**
 * @flow
 */

import * as React from "react"
import { t as globalT } from "helpers/i18n"
import Button from "components/Button"
import FileItem from "components/FileButton/FileItem"
import Label from "../Label"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.modal.file.${key}`, opts)

type Props = {
  error: ?string,
  onChange: (verification: ?File) => ?mixed,
  value: ?File,
  verificationTypes: Array<string>,
}

export default class FileSection extends React.PureComponent<Props> {
  form: ?HTMLFormElement

  handleFileChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.props.onChange(event.target.files[0])
  }

  handleFileRemoved: () => void = () => {
    if (this.form) {
      this.form.reset()
      this.props.onChange(null)
    }
  }

  handleClickAddFile: () => void = () => {
    const input = this.form && this.form.elements.namedItem("fileInput")

    input && input.click()
  }

  setForm: (node: ?HTMLFormElement) => void = (node: ?HTMLFormElement) => {
    this.form = node
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.fileSection}>
        <div className={styles.titleSection}>
          <Label text={t("title")} />
          <span className={styles.hints}>{t("hints")}</span>
        </div>
        {this.props.value != null ? (
          <FileItem
            fileName={this.props.value.name}
            onFileRemoved={this.handleFileRemoved}
            url={this.props.value.name} // bypass loading state
          />
        ) : (
          <Button iconLeft="add" label={t("add")} onClick={this.handleClickAddFile} type="action" />
        )}
        <div className={styles.error}>{this.props.error != null && this.props.error}</div>
        <form hidden ref={this.setForm}>
          <input
            accept={this.props.verificationTypes.join(", ")}
            id="fileInput"
            onChange={this.handleFileChange}
            type="file"
          />
        </form>
      </div>
    )
  }
}
