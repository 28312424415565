/**
 * @flow
 */
import * as React from "react"
import moment from "moment"
import { uniq } from "lodash"
import { t as globalT } from "helpers/i18n"
import type { DailyBreakdown, PptSchedule } from "time_off/Modal/types"
import { START_TIME, FINISH_TIME, HOURS } from "../../../../Modal/edit_daily_breakdown/helpers/constants"
import Label from "../../Label"
import BreakdownRow from "./BreakdownRow"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.modal.breakdown.${key}`, opts)

type Props = {
  daily_breakdown: DailyBreakdown,
  pendingPptAcceptanceDates: { [date: string]: Array<PptSchedule> },
}

export default class Breakdown extends React.PureComponent<Props> {
  render(): React.Element<"div"> {
    return (
      <div className={styles.breakdownWrapper}>
        <Label text={t("breakdown")} />
        {(() => {
          if (this.props.daily_breakdown != null) {
            const breakdownForView = this.props.daily_breakdown.filter((summary) => summary[HOURS] != null)

            const datesForView = uniq(breakdownForView.map((summary) => summary.date)).sort()

            return datesForView.slice(0, 5).map((date) => {
              const timePreference = window.time_formatter.twelve_hour_time() ? 12 : 24

              const displaySummaries = this.props.daily_breakdown
                .filter((summary) => summary.date === date)
                .sort((a, b) => {
                  if (a[START_TIME] < b[START_TIME]) {
                    return -1
                  } else {
                    return 1
                  }
                })
                .map((summary) => ({
                  displayStartTime: moment(summary[START_TIME]).isValid()
                    ? window.time_formatter.roster_validation_field(summary[START_TIME], timePreference)
                    : summary[START_TIME] != null
                    ? summary[START_TIME]
                    : "",
                  displayFinishTime: moment(summary[FINISH_TIME]).isValid()
                    ? window.time_formatter.roster_validation_field(summary[FINISH_TIME], timePreference)
                    : summary[FINISH_TIME] != null
                    ? summary[FINISH_TIME]
                    : "",
                  displayAllDay: summary.all_day,
                  displayHours: summary.hours,
                  id: summary.id,
                  isHoliday: summary.is_holiday,
                }))

              return (
                <BreakdownRow
                  date={date}
                  displaySummaries={displaySummaries}
                  key={date}
                  pendingPptAcceptance={this.props.pendingPptAcceptanceDates[date]}
                />
              )
            })
          }
        })()}
      </div>
    )
  }
}
