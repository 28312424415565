// @flow

export const ZERO_HOURS = 0
export const ID = "id"
export const DATE = "date"
export const START_TIME = "start_time"
export const FINISH_TIME = "finish_time"
export const INVALID_TIME = "Invalid Time"
export const ALL_DAY = "all_day"
export const HOURS = "hours"
export const DEPARTMENT_ID = "department_id"
export const IS_HOLIDAY = "is_holiday"
export const TIMES = "times"
export const TIME_FORMATS = ["hmm a", "HH:mm", "hh:mmA", "hh:mm a"]
export const FORMAT_12 = "h:mm a"
export const FORMAT_24 = "HH:mm"
