// @flow

export type ScheduleBreakType = {|
  +automatic_break: boolean,
  +finish: string,
  +id: ?number,
  +length: number,
  +paid: boolean,
  +start: string,
|}

export function someRequirePublishing(
  scheduleBreaks: Array<ScheduleBreakType>,
  publishedScheduleBreaks: Array<ScheduleBreakType>
): boolean {
  return scheduleBreaks.some((s) => !publishedScheduleBreaks.some((ps) => isPublishedSame(s, ps)))
}

export function isPublishedSame(sb: ScheduleBreakType, psb: ScheduleBreakType): boolean {
  return sb.start === psb.start && sb.finish === psb.finish && sb.paid === psb.paid && sb.length === psb.length
}
