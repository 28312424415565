/**
 * @flow
 */
import * as React from "react"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import Avatar from "components/Avatar"
import { LargeFilterSelect } from "components/Select/FilterSelect"
import { type SelectEvent } from "components/Select"
import type { LeaveUser } from "../../../Modal/types"
import Label from "../Label"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.modal.user_section.${key}`, opts)

type Props = {
  editable: boolean,
  onUserIdChange: (userId: string) => void,
  userId: ?string,
  users: Array<LeaveUser>,
}

const KNOWN_TAGS = ["Casual", "Full Time", "Part Time", "Salaried"]

export default function UserSection(props: Props): React.Node {
  const user = React.useMemo(() => props.users.find((user) => user.id === props.userId), [props.users, props.userId])

  const options = React.useMemo(
    () =>
      _.sortBy(
        props.users.map((user) => ({ label: user.name, value: user.id })),
        "label"
      ),
    [props.users]
  )

  const onUserIdChange = React.useCallback(
    (event: SelectEvent) => {
      props.onUserIdChange(event.target.value)
    },
    [props]
  )

  return (
    <div className={styles.userSelection}>
      <div className={styles.selector}>
        <Label required text={t("title")} />
        {props.editable ? (
          <LargeFilterSelect
            onChange={onUserIdChange}
            options={options}
            placeholder={t("placeholder")}
            value={props.userId}
          />
        ) : (
          <div className={styles.name}>{user?.name}</div>
        )}
        <div className={styles.employmentType}>{user?.awardTags.find((t) => KNOWN_TAGS.includes(t))}</div>
        <div className={styles.employmentType}>{user?.employmentEndDate}</div>
      </div>
      <div className={styles.avatar}>
        <Avatar alt="photo" size="m" source={user?.cachedUrl} type="round" />
      </div>
    </div>
  )
}
