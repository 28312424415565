// @flow
import _ from "lodash"
import * as HelperFunc from "rosters/overview/helpers/functions"
import type { PredictionModifierType, CognitiveDemandConfigType } from "rosters/overview/types"
import * as Constants from "rosters/overview/helpers/constants"
import type { DateData, RawStatData } from "./types"

export const getUniqKey = (date_data: DateData): string =>
  date_data.date + "~" + date_data.stat_type + "~" + date_data.data_stream_id
export const getDateKey = (date_data: DateData): string => date_data.date + "~" + date_data.stat_type
export const getUniqKeyForBreakdown = (date_data: DateData): string =>
  date_data.stat_type + "~" + date_data.data_stream_id

export const transformRawDataToDateData = (raw_data: Array<RawStatData>, date: string): Array<DateData> => {
  const by_ds = _.groupBy(raw_data, (d) => d.data_stream_id)
  const by_stat_type_by_ds: { [ds_id: string]: { [stat_type: string]: Array<RawStatData> } } = _.mapValues(
    by_ds,
    (raw_data_for_ds, ds) => _.groupBy(raw_data_for_ds, (d) => d.stat_type)
  )
  const unflattened: Array<Array<[string, string, Array<RawStatData>]>> = _.toPairs(by_stat_type_by_ds).map(
    ([ds_id, by_stat_type]: [string, { [stat_type: string]: Array<RawStatData> }]) =>
      _.toPairs(by_stat_type).map(([stat_type, raw_data_for_stat_type]: [string, Array<RawStatData>]) => [
        ds_id,
        stat_type,
        raw_data_for_stat_type,
      ])
  )
  // $FlowFixMe
  const flattened: Array<[string, string, Array<RawStatData>]> = _.flatten(unflattened, 1)
  return flattened.map<DateData, _>(
    ([ds_id, stat_type, raw_data_for_stat_and_ds]: [string, string, Array<RawStatData>]) => ({
      data_stream_id: Number(ds_id),
      date,
      stat_by_15: getStatBy15FromData(raw_data_for_stat_and_ds, date, false),
      stat_type: stat_type,
      original_stat_by_15: getStatBy15FromData(raw_data_for_stat_and_ds, date, true),
    })
  )
}

export const sumAll = (
  stat_by_15: { [minute: string]: number },
  data_span_of_hours: { finish: number, start: number }
): number =>
  _.toPairs(stat_by_15)
    .filter(([min, val]) => Number(min) < data_span_of_hours.finish && Number(min) >= data_span_of_hours.start)
    .reduce((acc, [min, val]) => acc + val, 0)

export const getStatBy15FromData = (
  data: Array<RawStatData>,
  ref_date: string,
  original_stat: boolean
): { [minute: string]: number } => {
  const first_data = data[0]
  const start_of_date_min = HelperFunc.dateTimeToMin(ref_date + " 00:00:00")
  // Any sales that take place inside a bucket are floored to closest 15min, eg a stat at 11:29 is floored to 11:15
  // Written this disgusting way for perf reasons, don't @ me
  const stat_by_15: { [min: string]: number } = {}
  for (let i = 0; i < data.length; i++) {
    const key = String(Math.floor((HelperFunc.dateTimeToMin(data[i].time) - start_of_date_min) / 15) * 15)
    if (original_stat) {
      stat_by_15[key] = (stat_by_15[key] || 0) + (+data[i].original_stat || +data[i].stat)
    } else {
      stat_by_15[key] = (stat_by_15[key] || 0) + +data[i].stat
    }
  }
  const data_interval = (first_data && first_data.data_interval) || "900"
  const buckets_in_interval = Math.round(Number(data_interval) / 900)
  if (data_interval !== "900" && first_data.source !== "user_entered") {
    // If the data interval is not 15 min, we normalize it down to 15 min by spreading out the data across the interval
    _.sortBy(_.toPairs(stat_by_15), (s) => Number(s[0])).map(([min, value]) => {
      stat_by_15[min] = 0
      _.range(0, buckets_in_interval, 1).map((i) => {
        const bucket = String(Number(min) - i * 15)
        stat_by_15[bucket] = (stat_by_15[bucket] || 0) + value / buckets_in_interval
      })
    })
  }
  return stat_by_15
}

type SpanOfHours = {|
  finish: number,
  start: number,
|}

export const createPredictionFromFineTune = (
  datums: Array<DateData>,
  date: string,
  stat_type: string,
  data_stream_id: number,
  growth_percentages: Array<PredictionModifierType>,
  crop?: SpanOfHours
): DateData => ({
  data_stream_id: data_stream_id,
  date,
  stat_by_15: HelperFunc.applyPercentageModifiers(
    HelperFunc.applyPercentageModifier(
      datums.reduce((acc, d) => HelperFunc.mergeAndAddStats(acc, get_stats_by_type(stat_type, crop, d)), {}),
      1 / datums.length
    ),
    growth_percentages
  ),
  original_stat_by_15: datums.reduce((acc, d) => ({ ...d.original_stat_by_15 }), {}),
  stat_type,
})

export const createPredictionFromGrowthPercentage = (
  datums: Array<DateData>,
  date: string,
  stat_type: string,
  data_stream_id: number,
  growth_percentage: number,
  forecasting_strategy: string,
  config: ?CognitiveDemandConfigType,
  crop?: SpanOfHours
): DateData => ({
  data_stream_id: data_stream_id,
  date,
  stat_by_15: HelperFunc.applyPercentageModifier(
    HelperFunc.applyPercentageModifier(
      datums.reduce((acc, d) => HelperFunc.mergeAndAddStats(acc, get_stats_by_type(forecasting_strategy, crop, d)), {}),
      1 / calculateDivisor(forecasting_strategy, config, datums)
    ),
    growth_percentage
  ),
  original_stat_by_15: datums.reduce((acc, d) => get_stats_by_type(forecasting_strategy, crop, d), {}),
  stat_type,
})

export function calculateDivisor(
  strategy: string,
  config: ?CognitiveDemandConfigType,
  datums: Array<DateData>
): number {
  const config_multiple_dates_length = config?.multiple_dates?.length || 0
  return strategy !== Constants.AVERAGE_OF_DATES || config_multiple_dates_length === 0
    ? datums.length || 1
    : config_multiple_dates_length
}

export function get_stats_by_type(
  forecasting_strategy: string,
  crop?: SpanOfHours,
  data: DateData
): { [minute: string]: number } {
  const original_stat_by_15 =
    Object.values(data.original_stat_by_15).length === 0 ? data.stat_by_15 : data.original_stat_by_15
  const stats = forecasting_strategy === Constants.AVERAGE_OF_DATES ? data.stat_by_15 : original_stat_by_15
  if (crop) {
    return crop_stats(stats, crop)
  }
  return stats
}

export function crop_stats(a: { [minute: string]: number }, crop: SpanOfHours): { [minute: string]: number } {
  const new_obj = {}
  const pairs: Array<[string, number]> = _.toPairs(a)
  pairs.forEach(([k, v]) => {
    const min = Number(k)
    if (min >= crop.start && min < crop.finish) {
      new_obj[k] = v
    }
  })
  return new_obj
}

export function merge_and_add_stats(
  a: { [minute: string]: number },
  b: { [minute: string]: number }
): { [minute: string]: number } {
  if (!(a && b)) {
    return a || b
  }

  const new_obj = {}
  const objs = [a, b]

  objs.forEach((obj) => {
    _.toPairs(obj).forEach(([k, v]) => {
      new_obj[k] = (new_obj[k] || 0) + obj[k]
    })
  })
  return new_obj
}

export default getStatBy15FromData
