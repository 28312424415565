/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import LeaveModal from "time_off/Modal/leave_request"
import Actions from "timesheets/logic/actions"

class LeaveContainer extends React.PureComponent {
  static defaultProps = { defaults: {} }

  handleSuccess = (leaveInfo) => {
    const user = this.props.users.get(parseInt(this.props.defaults.userId, 10))

    leaveInfo.dates.forEach((date) =>
      this.props.dispatch(
        Actions.shiftsCreate({
          date,
          leave_request: {
            reason: leaveInfo.reason,
            hours: leaveInfo.hours,
            leave_type: leaveInfo.leave_type,
            start: leaveInfo.start,
            end: leaveInfo.finish,
          },
          leave_request_id: leaveInfo.id,
          status: leaveInfo.status.toUpperCase(),
          timesheet_id: null,
          user_id: user.get("id"),
          user_name: user.get("name"),
        })
      )
    )

    this.props.dispatch(Actions.appCloseLeaveModal())
  }

  handleCancel = () => {
    this.props.dispatch(Actions.appCloseLeaveModal())
  }

  render() {
    return (
      <LeaveModal
        defaults={this.props.defaults || {}}
        onCancel={this.handleCancel}
        onSuccess={this.handleSuccess}
        open={this.props.defaults != null}
      />
    )
  }
}

LeaveContainer.defaultProps = { userId: null }
LeaveContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  defaults: PropTypes.object,
  users: PropTypes.object.isRequired,
}

export default connect((state) => ({
  defaults: state.application.get("leaveModalDefaults"),
  users: state.users,
}))(LeaveContainer)
