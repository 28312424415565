/**
 * @flow
 */
import * as React from "react"
import moment from "moment"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import * as NumberHelpers from "helpers/number_helpers"
import * as LeaveHelpers from "helpers/leave_helpers"
import Notice from "components/Notice"
import { FilterSelect } from "components/Select/FilterSelect"
import type { CurrentUser, LeaveUser, DailyBreakdown, LeaveBalance } from "time_off/Modal/types"
import Label from "../Label"
import DepartmentOption from "./DepartmentOption"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.modal.details.${key}`, opts)

type Props = {|
  currentUser: CurrentUser,
  +daily_breakdown: DailyBreakdown,
  +departmentId: ?string,
  +fallbackLeaveTypeName: ?string,
  +leaveTypeName: ?string,
  +onDepartmentIdChange: (departmentId: string) => ?mixed,
  +onFallbackLeaveTypeChange: (fallbackLeaveType: string) => ?mixed,
  +onLeaveTypeChange: (leaveType: string) => ?mixed,
  +startDate: moment,
  +userId: ?string,
  +users: Array<LeaveUser>,
|}

type State = {|
  departments: Array<{|
    +data: {|
      +color: string,
    |},
    +label: string,
    +value: string,
  |}>,
  leaveBalances: Array<{|
    +label: string,
    +value: string,
  |}>,
|}

export default class DetailSection extends React.PureComponent<Props, State> {
  // If numOfBreakdownTeams is 0 or null, that predicate will be falsy
  static defaultTeamPlaceholder: (numOfBreakdownTeams: ?number) => {|
    data: {| color: string |},
    label: string,
    value: string,
  |} = (numOfBreakdownTeams: ?number) =>
    !numOfBreakdownTeams || numOfBreakdownTeams < 2
      ? {
          data: { color: "#DDDDDD" },
          label: t("team_placeholder"),
          value: "PLACEHOLDER",
        }
      : {
          data: { color: "#DDDDDD" },
          label: t("multiple_teams", { number: numOfBreakdownTeams }),
          value: "PLACEHOLDER",
        }

  leaveBalancesAsOptions: () => Array<{| +label: string, +value: ?string |}> = (): Array<{|
    +label: string,
    +value: ?string,
  |}> => {
    const { userId, users } = this.props
    const user = users.find((u) => u.id === userId)

    if (user == null) {
      return []
    }

    if (user?.leaveBalances == null) {
      return [{ label: t("loading"), value: undefined }]
    }

    return _.sortBy(
      user.leaveBalances.map((lb) => {
        if (lb.hours) {
          const leaveBalanceLabel =
            lb.leaveType.viewInDays && lb.leaveType.defaultLeaveLength > 0
              ? t("leave_balance_label_days", {
                  days: NumberHelpers.roundWithDecimals(lb.hours / lb.leaveType.defaultLeaveLength, 4),
                  name: lb.leaveType.name,
                })
              : t("leave_balance_label", {
                  hours: NumberHelpers.roundWithDecimals(lb.hours, 4),
                  name: lb.leaveType.name,
                })
          return {
            label: leaveBalanceLabel,
            value: lb.leaveType.name,
          }
        } else {
          return { label: lb.leaveType.name, value: lb.leaveType.name }
        }
      }),
      "label"
    )
  }

  departmentsAsOptions: () => Array<{| +data: {| +color: string |}, +label: string, +value: ?string |}> = (): Array<{|
    +data: {| +color: string |},
    +label: string,
    +value: ?string,
  |}> => {
    const { userId, users } = this.props
    const user = users.find((u) => u.id === userId)

    if (user == null) {
      return []
    }

    if (user?.leaveBalances == null) {
      return [{ label: t("loading"), value: undefined, data: { color: "#FFF" } }]
    }

    const isMultiLocation = _.uniq(user.departments.map((d) => d.location.shortName)).length > 1

    return _.sortBy(
      user.departments.map((d) => ({
        data: {
          color: d.color == null ? "#DDDDDD" : d.color,
        },
        label: isMultiLocation ? t("new_department_label", { name: d.name, code: d.location.shortName }) : d.name,
        value: d.id,
      })),
      "label"
    )
  }

  handleDepartmentIdChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.props.onDepartmentIdChange(event.target.value)
  }

  handleLeaveTypeChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.props.onLeaveTypeChange(event.target.value)
  }

  handleFallbackLeaveTypeChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.props.onFallbackLeaveTypeChange(event.target.value)
  }

  canCreateAndApprove: () => void | boolean = () =>
    LeaveHelpers.canApproveLeaveRequestForUser(this.props.currentUser, this.props.userId)

  leaveBalancePrediction: () => null | React.Node = () => {
    if (!this.props.leaveTypeName) {
      return null
    }

    const dateAsString: ?string = LeaveHelpers.dateToString(this.props.startDate)
    if (!dateAsString) {
      return null
    }
    const user = this.props.users.find((u) => u.id === this.props.userId)

    if (user?.leaveBalances == null) {
      return null
    }

    const leaveBalance: ?LeaveBalance = LeaveHelpers.getLeaveBalance(user.leaveBalances, this.props.leaveTypeName)

    if (!leaveBalance) {
      return null
    }

    const viewInDays: boolean = leaveBalance.leaveType.viewInDays

    const prediction: ?number = LeaveHelpers.getLeaveBalancePrediction(leaveBalance, dateAsString, viewInDays)

    if (!prediction) {
      return null
    }

    const defaultLeaveLength: number = leaveBalance.leaveType.defaultLeaveLength

    const isMe: boolean = this.props.currentUser.id === this.props.userId

    if (
      !LeaveHelpers.showPrediction(
        prediction,
        LeaveHelpers.convertValueToHoursOrDays(leaveBalance.hours, defaultLeaveLength, viewInDays)
      )
    ) {
      return null
    }

    const accrual_prediction_title: string = LeaveHelpers.selectAccrualPredictionMessage(viewInDays, isMe)

    return (
      <Notice
        button={
          <a
            className="btn-magic"
            href="http://help.tanda.co/en/articles/4927899-projected-leave-balances"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t("accrual_prediction.help_link")}
          </a>
        }
        isFullWidth
        title={t(`accrual_prediction.title.${accrual_prediction_title}`, {
          date: this.props.startDate.format("ll"),
          prediction: NumberHelpers.roundWithDecimals(prediction, 2),
          name: user.name,
        })}
        type="magic"
      >
        {t(`accrual_prediction.body.${isMe ? "me" : "other"}`)}
      </Notice>
    )
  }

  shouldShowFallbackLeaveOptions: () => boolean = () => {
    // If fallback leave is enabled, we will always have a non null name (from the default)
    if (!this.props.fallbackLeaveTypeName) {
      return false
    }

    if (!this.props.leaveTypeName) {
      return true
    }

    const user = this.props.users.find((u) => u.id === this.props.userId)
    if (!user || !user.leaveBalances) {
      return false
    }
    return !!user.leaveBalances.find((lb) => lb.leaveType.name === this.props.leaveTypeName)
  }

  render(): React.Node {
    const user = this.props.users.find((u) => u.id === this.props.userId)
    const showFallbackLeaveOptions = this.shouldShowFallbackLeaveOptions()
    const breakdownTeams = _.uniq(
      this.props.daily_breakdown.map((shiftSummary) => shiftSummary.department_id).filter(Boolean)
    )
    const singleBreakdownTeam = breakdownTeams.length === 1 ? breakdownTeams[0] : null
    return (
      <React.Fragment>
        <div className={styles.details}>
          <div className={styles.column}>
            <Label required text={t("leave_type_title")} />
            <FilterSelect
              disabled={user?.leaveBalances?.length === 0}
              // $FlowFixMe doesnt have name but thats fine
              onChange={this.handleLeaveTypeChange}
              options={this.leaveBalancesAsOptions()}
              placeholder={t("leave_balance_placeholder")}
              size="l"
              value={this.props.leaveTypeName}
            />
            <div className={styles.noDepartments}>{user?.leaveBalances?.length === 0 && t("no_leave_types")}</div>
            {showFallbackLeaveOptions ? (
              <div className={styles.fallbackLeaveWrapper}>
                <Label text={t("fallback_leave_type_title")} />
                <FilterSelect
                  disabled={!showFallbackLeaveOptions}
                  // $FlowFixMe doesnt have name but thats fine
                  onChange={this.handleFallbackLeaveTypeChange}
                  options={this.leaveBalancesAsOptions().filter((lb) => lb.value !== this.props.leaveTypeName)}
                  placeholder={t("fallback_leave_type_placeholder")}
                  size="l"
                  value={this.props.fallbackLeaveTypeName}
                />
              </div>
            ) : null}
          </div>
          <div className={styles.column}>
            <Label text={t("team_title")} />
            <FilterSelect
              disabled={user?.departments?.length === 0}
              // $FlowFixMe doesnt have name but thats fine
              onChange={this.handleDepartmentIdChange}
              Option={DepartmentOption}
              options={this.departmentsAsOptions()}
              placeholder={DetailSection.defaultTeamPlaceholder(breakdownTeams.length)}
              size="l"
              // Prefer using breakdown team over LeaveRequest.department_id column
              // but if none exist, the column will do. If there are multiple breakdown teams,
              // show the multi team placeholder instead
              value={breakdownTeams.length <= 1 ? singleBreakdownTeam || this.props.departmentId : "PLACEHOLDER"}
            />
            <div className={styles.noDepartments}>{user?.departments?.length === 0 && t("no_departments")}</div>
          </div>
        </div>
        <div className="mb2">{this.leaveBalancePrediction()}</div>
      </React.Fragment>
    )
  }
}
