/* @flow */
import * as React from "react"
import cn from "classnames"
import moment from "moment"

import { t as globalT } from "helpers/i18n"

import { AutoHoverBubble } from "components/Bubble"
import type { ShiftSummary } from "../../types"
import Formatters from "../helpers/formatters"
import styles from "./styles.module.scss"

const t = (key, props) => globalT(`js.leave.edit_daily_breakdown_modal.${key}`, props)

type Props = {|
  filledFrom: string,
  initialShiftSummary: ShiftSummary,
  leaveRequestStatus: "approved" | "pending" | "rejected",
  size: "s" | "m" | "l",
|}

const classicFillStrategies = ["roster", "unpublished_roster", "regular_hours"]
const dirtyFillStrategies = ["roster_dirty", "unpublished_roster_dirty", "regular_hours_dirty"]
const renderableFillStrategies = [...classicFillStrategies, ...dirtyFillStrategies]

export default function FilledFromIcon(props: Props): null | React.Element<"div"> {
  const { filledFrom, size, leaveRequestStatus } = props
  if (!renderableFillStrategies.includes(filledFrom)) {
    return null
  }
  const initialBreakDuration =
    moment(props.initialShiftSummary.finish_time).diff(moment(props.initialShiftSummary.start_time), "hours", true) -
    props.initialShiftSummary.hours
  const anyInitialHours =
    props.initialShiftSummary.hours > 0 ||
    (props.initialShiftSummary.start_time != null && props.initialShiftSummary.finish_time)
  return (
    <div>
      <div className={styles.filledFrom}>
        <i
          className={cn({
            mi: true,
            "mi-filter-none": ["roster", "unpublished_roster", "roster_dirty", "unpublished_roster_dirty"].includes(
              filledFrom
            ),
            "mi-assignment": ["regular_hours", "regular_hours_dirty"].includes(filledFrom),
            [styles[size]]: true,
            [styles.icon]: classicFillStrategies.includes(filledFrom),
            [styles.lightIcon]: dirtyFillStrategies.includes(filledFrom),
          })}
          style={{ padding: 0, margin: 0 }}
        />
        <AutoHoverBubble>
          <div className={styles.tip}>
            {t(
              `filled_from_tooltip.${
                anyInitialHours || dirtyFillStrategies.includes(filledFrom) ? "" : "no_"
              }${filledFrom}`
            )}
          </div>
          {anyInitialHours && leaveRequestStatus !== "approved" ? (
            <React.Fragment>
              <div className={styles.tip}>
                {t(`filled_from_tooltip.start`, { time: Formatters.formatTime(props.initialShiftSummary.start_time) })}
              </div>
              {
                // If hours == 0, it's likely that this is a timed leave request whose times have absolutely no overlap with the autofilled hours
                // In that case we don't want to show breaks because the calculation will be wrong.
                // TODO: Add breaks for this case again once we actually track them in shift summaries; remove `props.initialShiftSummary.hours === 0 ? null`
                isNaN(initialBreakDuration) ||
                initialBreakDuration == null ||
                props.initialShiftSummary.hours === 0 ? null : (
                  <div className={styles.tip}>
                    {t(`filled_from_tooltip.breaks_duration`, { breaksDuration: initialBreakDuration })}
                  </div>
                )
              }
              <div className={styles.tip}>
                {t(`filled_from_tooltip.finish`, {
                  time: Formatters.formatTime(props.initialShiftSummary.finish_time),
                })}
              </div>
            </React.Fragment>
          ) : null}
        </AutoHoverBubble>
      </div>
    </div>
  )
}
