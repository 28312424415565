/**
 * @flow
 */
import * as React from "react"
import moment from "moment"
import cn from "classnames"
import { t as globalT } from "helpers/i18n"
import Text from "components/Text"
import { AutoHoverBubble } from "components/Bubble"
import type { PptSchedule } from "time_off/Modal/types"
import Formatter from "../../../../../Modal/edit_daily_breakdown/helpers/formatters"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.modal.breakdown.${key}`, opts)

type Props = {
  date: string,
  displaySummaries: Array<{
    displayAllDay: boolean,
    displayFinishTime: string,
    displayHours: number,
    displayStartTime: string,
    id: string,
    isHoliday: boolean,
  }>,
  pendingPptAcceptance: ?Array<PptSchedule>,
}

export default class Breakdown extends React.PureComponent<Props> {
  renderHolidayIcon: () => React.Element<"div"> = () => (
    <div className={styles.holidayDiv}>
      <span
        className={cn({
          mi: true,
          "mi-event": true,
          [styles.icon]: true,
        })}
      />
      <AutoHoverBubble>
        <span className={styles.tip}>{t("public_holiday")}</span>
      </AutoHoverBubble>
    </div>
  )

  renderPptAcceptanceWarning: (schedules: Array<PptSchedule>) => React.Element<"div"> = (
    schedules: Array<PptSchedule>
  ) => (
    <div className={styles.pptWarning}>
      <span
        className={cn({
          mi: true,
          "mi-check-circle": true,
          [styles.icon]: true,
        })}
      />
      <AutoHoverBubble>
        {schedules.map((schedule) => {
          if (schedule.start == null || schedule.finish == null) {
            return null
          }

          const start_time = moment(schedule.start).format("HH:mm")
          const finish_time = moment(schedule.finish).format("HH:mm")

          return (
            <div key={schedule.start}>
              <div className={styles.tip}>{t("pending_ppt_acceptance_warning.awaiting_acceptance")}</div>
              <div className={styles.tip}>{t("pending_ppt_acceptance_warning.start", { start_time })}</div>
              {schedule.breaks != null && schedule.breaks !== "" ? (
                <div className={styles.tip}>
                  {t("pending_ppt_acceptance_warning.breaks", { breaks: schedule.breaks.replace(/,/g, ", ") })}
                </div>
              ) : null}
              <div className={styles.tip}>{t("pending_ppt_acceptance_warning.finish", { finish_time })}</div>
              {schedule.automatic_break_length != null && schedule.automatic_break_length > 0 ? (
                <div className={styles.tip}>
                  {t("pending_ppt_acceptance_warning.automatic_break_length", {
                    automatic_break_length: schedule.automatic_break_length,
                  })}
                </div>
              ) : null}
            </div>
          )
        })}
      </AutoHoverBubble>
    </div>
  )

  render(): React.Element<"div"> {
    return (
      <div className={styles.breakdownRow}>
        <div className={styles.dateWrapper}>
          <Text color="lightest" type="small">
            {moment(this.props.date, "YYYY-MM-DD").format("ddd Do MMM")}
          </Text>
        </div>
        <div className={styles.summariesWrapper}>
          {this.props.displaySummaries.map((display) => (
            <div className={styles.summaryWrapper} key={`${this.props.date}-${display.id}`}>
              <div className={styles.timesWrapper}>
                <Text color="lightest" type="small">
                  {display.displayAllDay ? t("all_day") : display.displayStartTime + "-" + display.displayFinishTime}
                </Text>
              </div>
              <div>
                <Text color="lightest" type="small">
                  {display.displayHours != null
                    ? Formatter.shortenFloat(display.displayHours)
                    : t("hours", { hours: display.displayHours })}
                </Text>
              </div>
              <div className={styles.iconWrapper}>{display.isHoliday && this.renderHolidayIcon()}</div>
              <div className={styles.iconWrapper}>
                {this.props.pendingPptAcceptance && this.renderPptAcceptanceWarning(this.props.pendingPptAcceptance)}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
