/**
 * @flow
 */
import * as React from "react"
import moment from "moment"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import Input, { type InputColor } from "components/Input"
import Checkbox from "components/Checkbox"
import HolidayIcon from "components/HolidayIcon"
import Icon from "components/Icon"
import { FilterSelect } from "components/Select/FilterSelect"
import DepartmentOption from "../../../components/Form/DetailSection/DepartmentOption"
import type { Event, FieldTypes, Department, ShiftSummary, PptSchedule, User } from "../../types"
import styles from "../styles.module.scss"
import { ALL_DAY, START_TIME, FINISH_TIME, HOURS, DEPARTMENT_ID, TIME_FORMATS } from "../helpers/constants"
import FilledFromIcon from "./FilledFromIcon"
import PendingPptAcceptanceIcon from "./PendingPptAcceptanceIcon"

const t = (key, opts) => globalT(`js.leave.edit_daily_breakdown_modal.${key}`, opts)

type Props = {|
  all_day: boolean,
  can_edit: boolean,
  date: string,
  departmentId: string,
  departments: Array<Department>,
  disabled?: boolean, // TODO: Turn on for rejected leave requests
  error?: string,
  filled_from: string,
  finish_time?: string,
  handleAlldayChange: (event: Event) => void,
  handleClearingRowData: (id: string) => void,
  handleHoursBlur: (event: Event) => void,
  handleInputChange: (event: Event) => void,
  handleTimeBlur: (event: Event) => void,
  hours?: string | number,
  id: string,
  initialShiftSummary: ShiftSummary,
  pendingPptAcceptance: ?Array<PptSchedule>,
  start_time?: string,
  status: "approved" | "pending" | "rejected",
  // eslint-disable-next-line react/no-unused-prop-types
  user: ?User | string,
  user_id: string | number,
|}

export default class DailyBreakdownRow extends React.PureComponent<Props> {
  static defaultProps: {|
    disabled: boolean,
    error: void,
    finish_time: void,
    hours: void,
    start_time: void,
  |} = {
    disabled: false,
    error: undefined,
    finish_time: undefined,
    hours: undefined,
    start_time: undefined,
  }

  static defaultTeamPlaceholder: () => {| data: {| color: string |}, label: string, value: string |} = () => ({
    data: { color: "#DDDDDD" },
    label: t("team_placeholder"),
    value: "PLACEHOLDER",
  })

  static departmentsAsOptions: (
    departments: Array<Department>
  ) => Array<{| +data: {| +color: string |}, +label: string, +value: string |}> = (
    departments: Array<Department>
  ): Array<{| +data: {| +color: string |}, +label: string, +value: string |}> => {
    const isMultiLocation = _.uniq(departments.map((d) => d.location.shortName)).length > 1

    return _.sortBy(
      departments.map((d) => ({
        data: {
          color: d.color == null ? "#DDDDDD" : d.color,
        },
        label: isMultiLocation ? t("new_department_label", { name: d.name, code: d.location.shortName }) : d.name,
        value: d.id,
      })),
      "label"
    )
  }

  createChangeHandler(
    id: string,
    key: FieldTypes,
    handler: (event: SyntheticInputEvent<HTMLInputElement>) => void
  ): (e: SyntheticInputEvent<HTMLInputElement>) => void {
    return (e: SyntheticInputEvent<HTMLInputElement>) => {
      // $FlowFixMe this is all horribly typed
      handler({
        target: {
          value: e.target.value,
          id,
          key,
          index: 0,
        },
      })
    }
  }

  handleClearingRowData: (id: string) => () => void = (id: string) => () => {
    this.props.handleClearingRowData(id)
  }

  // If pending or rejected, the shifts do not yet exist so we will not be modifying exported shifts directly (ok to edit)
  // If approved, the shifts do exist on an exported timesheet, so its not ok to edit.
  shiftIsExported(): boolean {
    return this.props.status === "approved" && this.props.initialShiftSummary.timesheet_on_this_day_is_exported
  }

  isOvernight(): boolean {
    const start = moment(this.props.start_time, TIME_FORMATS)
    const finish = moment(this.props.finish_time, TIME_FORMATS)

    return start.isAfter(finish) && !this.props.all_day
  }

  renderFinishColor(): InputColor {
    if (this.props.error != null && this.props.error === FINISH_TIME) {
      return "error"
    } else if (this.isOvernight()) {
      return "overnight"
    } else {
      return "default"
    }
  }

  render(): React.Element<"div"> {
    const { date, all_day, id } = this.props
    let { start_time, finish_time, hours } = this.props
    start_time = start_time != null ? start_time : ""
    finish_time = finish_time != null ? finish_time : ""
    hours = hours != null ? hours : ""

    return (
      <div className={styles.dailyBreakdownRow} key={date}>
        <div className={styles.filledFromSpacer}>
          <span className={styles.iconSpacerFilledFrom}>
            <FilledFromIcon
              filledFrom={this.props.filled_from}
              initialShiftSummary={this.props.initialShiftSummary}
              leaveRequestStatus={this.props.status}
              size={"s"}
            />
          </span>
          <span className={styles.iconSpacerFilledFrom}>
            <PendingPptAcceptanceIcon pendingPptAcceptance={this.props.pendingPptAcceptance} size={"s"} />
          </span>
        </div>
        <div className={styles.dateSpacer}>
          <span className={styles.text}>{moment(date, "YYYY-MM-DD").format("ddd Do MMM YYYY")}</span>
          <span className={styles.iconSpacer}>
            <HolidayIcon date={moment(date, "YYYY-MM-DD")} size={"s"} userId={this.props.user_id} />
          </span>
        </div>
        <div className={styles.timeSpacer}>
          <div className={styles.timeCheckboxWrapper}>
            <Checkbox
              autoMargin={false}
              checked={all_day}
              disabled={!this.props.can_edit || this.shiftIsExported()}
              // $FlowFixMe this is all horribly typed
              onClick={this.createChangeHandler(id, ALL_DAY, this.props.handleAlldayChange)}
            />
            <span className={styles.timeSpanDisplay}>{t("all_day")}</span>
          </div>
          <div className={styles.formTimeInputWrapper}>
            <div className={styles.formInput}>
              <Input
                color={this.props.error != null && this.props.error === START_TIME ? "error" : "default"}
                disabled={!this.props.can_edit || all_day || this.shiftIsExported()}
                // $FlowFixMe this is all horribly typed
                onBlur={this.createChangeHandler(id, START_TIME, this.props.handleTimeBlur)}
                // $FlowFixMe this is all horribly typed
                onChange={this.createChangeHandler(id, START_TIME, this.props.handleInputChange)}
                placeholder={t("time_placeholder")}
                tipText={t("start_time")}
                value={all_day ? "" : start_time}
              />
            </div>
          </div>
          <div className={styles.formTimeInputWrapper}>
            <div className={styles.formInput}>
              <Input
                color={this.renderFinishColor()}
                disabled={!this.props.can_edit || all_day || this.shiftIsExported()}
                // $FlowFixMe this is all horribly typed
                onBlur={this.createChangeHandler(id, FINISH_TIME, this.props.handleTimeBlur)}
                // $FlowFixMe this is all horribly typed
                onChange={this.createChangeHandler(id, FINISH_TIME, this.props.handleInputChange)}
                placeholder={t("time_placeholder")}
                tipText={t("finish_time")}
                value={all_day ? "" : finish_time}
              />
              {this.isOvernight() && (
                <span className={styles.overnightIcon}>
                  <Icon size="xs" type="brightness-3" />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={styles.hoursSpacer}>
          <div className={styles.formInput}>
            <Input
              color={this.props.error != null && this.props.error === HOURS ? "error" : "default"}
              disabled={!this.props.can_edit || !all_day || this.shiftIsExported()}
              // $FlowFixMe this is all horribly typed
              onBlur={this.createChangeHandler(id, HOURS, this.props.handleHoursBlur)}
              // $FlowFixMe this is all horribly typed
              onChange={this.createChangeHandler(id, HOURS, this.props.handleInputChange)}
              placeholder={t("hours")}
              tipText={t("hours")}
              value={hours}
            />
          </div>
        </div>
        <div className={styles.teamSpacer}>
          <div className={styles.formTeamSelectWrapper}>
            <FilterSelect
              disabled={
                !this.props.can_edit ||
                this.props.disabled ||
                this.props.departments.length === 0 ||
                this.shiftIsExported()
              }
              // $FlowFixMe this is cooked but its fine
              onChange={this.createChangeHandler(id, DEPARTMENT_ID, this.props.handleInputChange)}
              Option={DepartmentOption}
              options={DailyBreakdownRow.departmentsAsOptions(this.props.departments)}
              placeholder={DailyBreakdownRow.defaultTeamPlaceholder()}
              size="s"
              value={this.props.departmentId}
            />
          </div>
          {this.props.can_edit && !this.shiftIsExported() && (
            <div
              className={styles.removeButton}
              onClick={this.handleClearingRowData(id)}
              onKeyUp={_.noop}
              role="button"
              tabIndex={0}
            >
              <Icon color="charcoal" size="s" type="delete" />
            </div>
          )}
        </div>
      </div>
    )
  }
}
