/* @flow */
import * as React from "react"
import cn from "classnames"
import moment from "moment"

import { t as globalT } from "helpers/i18n"

import { AutoHoverBubble } from "components/Bubble"
import type { PptSchedule } from "../../types"
import styles from "./styles.module.scss"

const t = (key, props) => globalT(`js.leave.edit_daily_breakdown_modal.${key}`, props)

type Props = {|
  pendingPptAcceptance: ?Array<PptSchedule>,
  size: "s" | "m" | "l",
|}

export default function PendingPptAcceptanceIcon(props: Props): null | React.Element<"div"> {
  const { pendingPptAcceptance, size } = props
  if (!pendingPptAcceptance) {
    return null
  }

  return (
    <div>
      <div className={styles.pendingPptAcceptance}>
        <i
          className={cn({
            mi: true,
            "mi-check-circle": true,
            [styles[size]]: true,
            [styles.icon]: true,
          })}
          style={{ padding: 0, margin: 0 }}
        />
        <AutoHoverBubble>
          {pendingPptAcceptance.map((schedule) => {
            if (schedule.start == null || schedule.finish == null) {
              return null
            }

            const start_time = moment(schedule.start).format("HH:mm")
            const finish_time = moment(schedule.finish).format("HH:mm")

            return (
              <div key={schedule.start}>
                <div className={styles.tip}>{t("pending_ppt_acceptance_warning.awaiting_acceptance")}</div>
                <div className={styles.tip}>{t("pending_ppt_acceptance_warning.start", { start_time })}</div>
                {schedule.breaks != null && schedule.breaks !== "" ? (
                  <div className={styles.tip}>
                    {t("pending_ppt_acceptance_warning.breaks", { breaks: schedule.breaks.replace(/,/g, ", ") })}
                  </div>
                ) : null}
                <div className={styles.tip}>{t("pending_ppt_acceptance_warning.finish", { finish_time })}</div>
                {schedule.automatic_break_length != null && schedule.automatic_break_length > 0 ? (
                  <div className={styles.tip}>
                    {t("pending_ppt_acceptance_warning.automatic_break_length", {
                      automatic_break_length: schedule.automatic_break_length,
                    })}
                  </div>
                ) : null}
              </div>
            )
          })}
        </AutoHoverBubble>
      </div>
    </div>
  )
}
