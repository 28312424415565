/**
 * @flow
 */

import * as React from "react"
import TextArea from "components/TextArea"
import { t as globalT } from "helpers/i18n"
import Label from "../Label"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.modal.reason.${key}`, opts)

type Props = {
  onChange: (reason: string) => void,
  value: string,
}

export default class ReasonSection extends React.PureComponent<Props> {
  handleChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length > 150) {
      return
    }

    this.props.onChange(event.target.value)
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.reason}>
        <div className={styles.header}>
          <Label text={t("title")} />
        </div>
        <TextArea minRows={4} onChange={this.handleChange} value={this.props.value} />
      </div>
    )
  }
}
