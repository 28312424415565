/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"

import Button from "components/Button"
import { CheckboxLabelled } from "components/Checkbox"

import { t as globalT } from "helpers/i18n"

import * as Shift from "timesheets/models/shift"

import Icon from "components/Icon"
import BreakTimeInput from "./BreakTimeInput"
import styles from "./styles.module.scss"

const t = (key) => globalT(`js.timesheets.shift_card.breaks_popover.${key}`)

export default class BreaksPopoverRow extends React.PureComponent {
  formatLength = (length) => {
    if (!length) {
      return "--"
    }

    return globalT("js.timesheets.duration.minutes", { minutes: Math.ceil(Math.abs(length)) })
  }

  handlePaidSelect = (event) => {
    if (this.props.disabled) {
      return
    }

    this.props.actions.shiftsSyncBreakNonTimeChange({
      shiftBreak: this.props.shiftBreak,
      fields: { paid: event.target.value },
    })
  }

  handleDeleteButton = () => {
    if (this.props.disabled) {
      return
    }

    this.props.actions.shiftsBreakClear({ shift: this.props.shift, shiftBreak: this.props.shiftBreak })
  }

  // don't show rows that have been fully blanked
  // true if shift break has an ID, AND has no data
  showBreakRow = () =>
    this.props.isFirstBreak || // always show at least one break
    Shift.hasMockedId(this.props.shiftBreak.get("id")) || // always show new records
    this.props.shiftBreak.get("length") !== 0 || // always show if has length
    this.props.shiftBreak.get("start").isValid() || // always show if has start
    this.props.shiftBreak.get("finish").isValid() // always show if has finish

  renderBreakIcon = () => {
    if (this.isAutoBreak()) {
      if (this.props.shiftBreak.get("length") > 0) {
        return <Icon color="black" type="flash-on" />
      }
    } else {
      return <Icon color="black" type="free-breakfast" />
    }
  }

  breakTimeInputIsDisabled = () => {
    if (this.props.disabled) {
      return true
    }
    if (this.isAutoBreak() && this.props.shiftBreak.get("length") > 0) {
      return true
    }
    return false
  }

  isAutoBreak() {
    return this.props.shiftBreak.get("break_type") === Shift.ShiftBreakType.AUTOMATIC_BREAK_RULE
  }

  renderInputs() {
    const startInvalid = !this.props.shiftBreak.get("start").isValid()
    const finishInvalid = !this.props.shiftBreak.get("finish").isValid()
    const bothInvalid = startInvalid && finishInvalid
    const showStartError = !bothInvalid && startInvalid
    const showFinishError = !bothInvalid && finishInvalid

    return (
      <div className={styles.breakInputs}>
        <BreakTimeInput
          actions={this.props.actions}
          clocked={this.props.shiftBreak.get("clocked_start")}
          currentUser={this.props.currentUser}
          date={this.props.shift.get("date")}
          disabled={this.breakTimeInputIsDisabled()}
          field="start"
          isAutoBreak={this.isAutoBreak()}
          rostered={this.props.shiftBreak.get("rostered_start")}
          shiftBreak={this.props.shiftBreak}
          showError={showStartError}
        />

        <BreakTimeInput
          actions={this.props.actions}
          clocked={this.props.shiftBreak.get("clocked_finish")}
          currentUser={this.props.currentUser}
          date={this.props.shift.get("date")}
          disabled={this.breakTimeInputIsDisabled()}
          field="finish"
          isAutoBreak={this.isAutoBreak()}
          rostered={this.props.shiftBreak.get("rostered_finish")}
          shiftBreak={this.props.shiftBreak}
          showError={showFinishError}
        />
      </div>
    )
  }

  render() {
    if (!this.showBreakRow()) {
      return null
    }

    return (
      <div className={styles.breaksPopoverRow}>
        <span className={styles.breakType}>{this.renderBreakIcon()}</span>

        {this.renderInputs()}

        <span className={styles.attributes}>{this.formatLength(this.props.shiftBreak.get("length"))}</span>

        <div className={styles.paidCheckbox}>
          <CheckboxLabelled
            autoMargin={false}
            checked={this.props.shiftBreak.get("paid")}
            disabled={this.props.disabled}
            label={t("paid")}
            onClick={this.handlePaidSelect}
          />
        </div>

        {!this.props.disabled && (
          <div className="self-start ml1">
            <Button iconLeft="delete" onClick={this.handleDeleteButton} size="sm" type="danger" weight="secondary" />
          </div>
        )}
      </div>
    )
  }
}

BreaksPopoverRow.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  currentUser: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFirstBreak: PropTypes.bool.isRequired,
  shift: PropTypes.object.isRequired,
  shiftBreak: PropTypes.object.isRequired,
}
