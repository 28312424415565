/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { noop } from "lodash"
import { handleOnClick, handleMouseEnter, handleMouseLeave } from "components/DropdownList/helpers/handlers"
import styles from "./styles.module.scss"

export default class DepartmentOption extends React.Component {
  static defaultProps = {
    data: {},
    hovered: false,
    noOverflow: false,
    onClick: noop,
    onMouseEnter: noop,
    onMouseLeave: noop,
    updateRef: noop,
    value: undefined,
  }

  constructor(props) {
    super(props)
    this.handleMouseEnter = handleMouseEnter.bind(this)
    this.handleMouseLeave = handleMouseLeave.bind(this)
    this.handleOnClick = handleOnClick.bind(this)
    this.state = { isMouseOver: false }
  }

  render() {
    return (
      <div
        className={cn(styles.departmentOption, styles[this.props.size], {
          [styles.noOverflow]: this.props.noOverflow,
          [styles.hovered]: this.props.hovered,
        })}
        onClick={this.handleOnClick}
        onKeyPress={noop}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.props.hovered ? noop : this.handleMouseEnter}
        ref={this.props.updateRef}
        role="button"
        tabIndex={-1}
      >
        <div className={styles.swatch} style={{ backgroundColor: this.props.data.color }} />
        <span className={styles.departmentLabel}>{this.props.label}</span>
      </div>
    )
  }
}

DepartmentOption.propTypes = {
  data: PropTypes.shape({ color: PropTypes.string.isRequired }),
  label: PropTypes.string.isRequired,
  noOverflow: PropTypes.bool,
  hovered: PropTypes.bool,
  size: PropTypes.oneOf(["s", "m", "l"]).isRequired,
  updateRef: PropTypes.func,

  // ** Used in helpers/defaultOptionHandlers.js ** //
  /* eslint-disable react/no-unused-prop-types */
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  value: PropTypes.any,
  /* eslint-enable react/no-unused-prop-types */
  // ** Used in helpers/defaultOptionHandlers.js ** //
}
