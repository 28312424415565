/**
 * @flow
 */

import _ from "lodash"

// verify ABN as per https://abr.business.gov.au/Help/AbnFormat
// tests: test/webpack/helpers/validate_abn.test.js
const validateABN = (rawABN: string): boolean => {
  if (!rawABN) {
    return false
  }

  const abn = rawABN.replace(/ /g, "")
  if (abn.length !== 11) {
    return false
  }

  const abnDigits = _.toArray(abn).map((x) => parseInt(x, 10))
  abnDigits[0] = abnDigits[0] - 1
  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]

  const sum = abnDigits.map((digit, i) => digit * weights[i]).reduce((acc, curr) => acc + curr, 0)

  return sum % 89 === 0
}

export default validateABN
