/**
 * @flow
 */
import * as React from "react"
import _ from "lodash"
import moment from "moment"
import { t as globalT } from "helpers/i18n"
import Button from "components/Button"
import Loader from "components/Loader"
import Text from "components/Text"
import * as Type from "time_off/Modal/types"
import Notice from "components/Notice"
import { FilterSelect } from "components/Select/FilterSelect"
import { DATE, IS_HOLIDAY, TIMES } from "../../../Modal/edit_daily_breakdown/helpers/constants"
import EditDailyBreakdown from "../../../Modal/edit_daily_breakdown/index"
import Label from "../Label"
import Breakdown from "./Breakdown"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.modal.breakdown.${key}`, opts)

type Props = {
  currentUser: Type.CurrentUser,
  daily_breakdown: Type.DailyBreakdown,
  fetchingDailyBreakdown: boolean,
  fetchingLeaveAppliesOnDates: boolean,
  fillFromRosterStrategy: Type.FillFromRosterStrategy,
  initialDailyBreakdown: ?Type.DailyBreakdown,
  leaveAppliesOnDates: { [string]: boolean },
  onFillFromRosterStrategyChange: (strategy: Type.FillFromRosterStrategy) => void,
  onSaveDailyBreakdown: (Type.DailyBreakdown) => void,
  pendingPptAcceptanceDates: { [date: string]: Array<Type.PptSchedule> },
  timeFormat: 12 | 24,
  userId: string | number,
  users: Array<Type.LeaveUser>,
}

type State = {
  open: boolean,
}

export default class BreakdownSection extends React.PureComponent<Props, State> {
  static fillFromRosterStrategiesAsOptions: (
    strategies: Array<Type.FillFromRosterStrategy>
  ) => Array<{| +label: string, +value: string |}> = (
    strategies: Array<Type.FillFromRosterStrategy>
  ): Array<{| +label: string, +value: string |}> =>
    strategies.map((strategy) => ({
      label: t(`fill_from_roster.${strategy}`),
      value: strategy,
    }))

  state: State = { open: false }

  onCancel: () => void = () => {
    this.setState({ open: !this.state.open })
  }

  onSuccess: (submitObject: $Diff<Type.BreakdownDefaults, Type.NonSubmittableBreakdownDefaults>) => void = (
    submitObject: $Diff<Type.BreakdownDefaults, Type.NonSubmittableBreakdownDefaults>
  ) => {
    this.onCancel()
    this.props.onSaveDailyBreakdown(submitObject.daily_breakdown)
  }

  handleFillFromRosterStrategyChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    if (event.target.value !== "vacate" && event.target.value !== "delete" && event.target.value !== "keep") {
      return
    }

    this.props.onFillFromRosterStrategyChange(event.target.value)
  }

  /* Responsible for displaying the overflow text.
  Takes baseAmount as paramater to subtract from total amount of days within
  the daily breakdown. (Indicative of the slice).
   */
  displayOverflow: (baseAmount: number) => void | React.Node = (baseAmount: number) => {
    // We used to filter out summaries with no hours, but since we can have non-weekend ones
    // like that, it looks more confusing if we omit them
    const numDisplayableSummaries = _.uniq(this.props.daily_breakdown.map((breakdown) => breakdown[DATE])).length
    if (numDisplayableSummaries > 5) {
      const overflow = numDisplayableSummaries - baseAmount
      return (
        <Text color="lightest" type="small">
          {t("overflow", { overflow })}
        </Text>
      )
    }
  }

  formatMoment: (time: string | number) => string = (time: string | number): string => {
    if (typeof time === "number" && time % 1 !== 0) {
      // It's a fraction, cause thats how we store ones that aren't on the hour
      return moment().startOf("day").add(time, "hours").format("HH:mm")
    }
    const stringified = String(time)
    if (this.props.timeFormat === 24) {
      return moment(stringified, ["hmm", "hmma", "HH:mm", "hh:mmA", "hh:mm a"]).format("HH:mm")
    } else {
      return moment(stringified, ["hmm", "hmma", "HH:mm", "hh:mmA", "hh:mm a"]).format("hh:mm A")
    }
  }

  partialHolidayExists: (holidays: Array<Type.ShiftSummary>) => boolean = (holidays: Array<Type.ShiftSummary>) =>
    holidays.some(
      (holiday) =>
        holiday[IS_HOLIDAY] &&
        !((holiday[TIMES]?.from == null && holiday[TIMES]?.to == null) || _.isEmpty(holiday[TIMES]))
    )
  allDayHolidayExists: (holidays: Array<Type.ShiftSummary>) => boolean = (holidays: Array<Type.ShiftSummary>) =>
    holidays.some(
      (holiday) =>
        holiday[IS_HOLIDAY] &&
        ((holiday[TIMES]?.from == null && holiday[TIMES]?.to == null) || _.isEmpty(holiday[TIMES]))
    )

  renderPartialAndAllDayHoliday: (dates: Array<Type.ShiftSummary>) => React.Node = (
    dates: Array<Type.ShiftSummary>
  ): React.Node => {
    const holidayDates: Array<Type.HolidayBreak> = dates
      .filter((date) => date[IS_HOLIDAY])
      .map((date) => ({ date: date[DATE], times: date[TIMES] }))
    const partialPhs: Array<Type.HolidayBreak> = holidayDates.filter(
      (date) => !((date[TIMES]?.from == null && date[TIMES]?.to == null) || _.isEmpty(date[TIMES]))
    )
    const partialPhDates: Array<string> = partialPhs.map((date) => date[DATE])
    const allDayPhDates: Array<string> = holidayDates
      .filter((date) => (date[TIMES]?.from == null && date[TIMES]?.to == null) || _.isEmpty(date[TIMES]))
      .map((date) => date[DATE])

    const formattedPartialDates: string = partialPhDates.map((date) => new Date(date).toDateString()).join(", ")
    const formattedAllDayDates: string = allDayPhDates.map((date) => new Date(date).toDateString()).join(", ")

    const partialTimes: Array<Type.PartialTimeBounds> = partialPhs.map((date) => date[TIMES]).filter(Boolean)
    const formattedPartialTimes: string = partialTimes
      .map((time) => `${this.formatMoment(time["from"])} - ${this.formatMoment(time["to"])}`)
      .join(", ")

    return (
      <div className="pt1">
        <Notice iconType="error" title={t("notifications.both_times_title")} type="warning">
          {t("notifications.both_times", {
            dates: formattedPartialDates,
            all_day_dates: formattedAllDayDates,
            times: formattedPartialTimes,
          })}
        </Notice>
      </div>
    )
  }

  renderPartialHoliday: (dates: Array<Type.ShiftSummary>) => React.Node = (
    dates: Array<Type.ShiftSummary>
  ): React.Node => {
    const holidayDates: Array<Type.HolidayBreak> = dates
      .filter((date) => date[IS_HOLIDAY])
      .map((date) => ({ date: date.date, times: date.times }))
    const partialPhDates: Array<string> = holidayDates
      .filter((date) => !((date[TIMES]?.from == null && date[TIMES]?.to == null) || _.isEmpty(date[TIMES])))
      .map((date) => date[DATE])
    const formattedPartialDates: string = partialPhDates.map((date) => new Date(date).toDateString()).join(", ")

    const partialTimes: Array<Type.PartialTimeBounds> = holidayDates.map((date) => date[TIMES]).filter(Boolean)
    const formattedPartialTimes: string = partialTimes
      .map((time) => `${this.formatMoment(time["from"])} - ${this.formatMoment(time["to"])}`)
      .join(", ")

    return (
      <div className="pt1">
        <Notice
          iconType="error"
          text={t("notifications.partial_times", { dates: formattedPartialDates, times: formattedPartialTimes })}
          title={t("notifications.partial_times_title")}
          type="warning"
        />
      </div>
    )
  }

  renderAllDayHoliday: (dates: Array<Type.ShiftSummary>) => React.Node = (
    dates: Array<Type.ShiftSummary>
  ): React.Node => {
    const holidayDates: Array<string> = dates.filter((date) => date[IS_HOLIDAY]).map((date) => date[DATE])
    const formattedDates: string = holidayDates.map((date) => new Date(date).toDateString()).join(", ")

    return (
      <div className="pt1">
        <Notice
          iconType="error"
          text={t("notifications.all_day", { dates: formattedDates })}
          title={t("notifications.all_day_title")}
          type="warning"
        />
      </div>
    )
  }

  renderPublicHolidayNotification: () => void | React.Node = () => {
    // see leave_applies_on_dates in app/controllers/leave_controller.rb
    // we don't want to show the notification for leave types that apply on public holidays so we filter them out here
    const arrayOfHolidays: Array<Type.ShiftSummary> = this.props.daily_breakdown.filter((summary) => summary.is_holiday)
    // The above array is for dates/times that leave is not costed on.

    if (this.partialHolidayExists(arrayOfHolidays) && this.allDayHolidayExists(arrayOfHolidays)) {
      return this.renderPartialAndAllDayHoliday(arrayOfHolidays)
    }
    if (this.partialHolidayExists(arrayOfHolidays)) {
      return this.renderPartialHoliday(arrayOfHolidays)
    }
    if (this.allDayHolidayExists(arrayOfHolidays)) {
      return this.renderAllDayHoliday(arrayOfHolidays)
    }
  }

  render(): React.Element<"div"> {
    const user = this.props.users.find((u) => u.id === this.props.userId)

    return (
      <div>
        {this.props.fetchingDailyBreakdown && (
          <div className={styles.breakdownWrapper}>
            <Label text={t("breakdown")} />
            <Loader color="primary" size="l" />
          </div>
        )}
        {this.props.daily_breakdown != null && this.props.daily_breakdown.length > 0 && (
          <div className={styles.breakdownWrapper}>
            <Breakdown
              daily_breakdown={this.props.daily_breakdown}
              pendingPptAcceptanceDates={this.props.pendingPptAcceptanceDates}
              userId={this.props.userId}
            />

            {this.displayOverflow(5)}
            <div className="my1">
              <Button
                iconLeft="edit"
                label={this.props.currentUser.isManager ? t("edit_breakdown") : t("view_breakdown")}
                onClick={this.onCancel}
                size="sm"
                type="ghost"
              />
            </div>
            {this.props.currentUser.isManager &&
            this.props.daily_breakdown.some(
              (shift_summary) =>
                shift_summary.filled_from === "roster" || shift_summary.filled_from === "unpublished_roster"
            ) ? (
              <div className={styles.fillFromRosterStrategyWrapper}>
                <FilterSelect
                  // $FlowFixMe doesnt have name but thats fine
                  onChange={this.handleFillFromRosterStrategyChange}
                  options={BreakdownSection.fillFromRosterStrategiesAsOptions(["keep", "vacate", "delete"])}
                  placeholder={t("fill_from_opt_placeholder")}
                  size="s"
                  value={this.props.fillFromRosterStrategy}
                />
              </div>
            ) : null}
            <EditDailyBreakdown
              defaults={{
                can_edit: this.props.currentUser.isManager,
                daily_breakdown: this.props.daily_breakdown,
                initialDailyBreakdown: this.props.initialDailyBreakdown,
                pending_ppt_acceptance_dates: this.props.pendingPptAcceptanceDates,
                errors: {},
                hours: 0,
                shift_attach_last_started_at: null,
                shift_attach_last_finished_at: null,
                id: 1,
                status: "pending",
                user: "",
                userId: this.props.userId,
              }}
              departments={user?.departments || []}
              onCancel={this.onCancel}
              onSuccess={this.onSuccess}
              open={this.state.open}
            />
            {!this.props.fetchingLeaveAppliesOnDates && this.renderPublicHolidayNotification()}
          </div>
        )}
      </div>
    )
  }
}
