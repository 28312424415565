/**
 * @flow
 */
import * as React from "react"
import { t as globalT } from "helpers/i18n"
import Button from "components/Button"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.modal.failure.${key}`, opts)

type Props = {|
  backAction: ?() => void,
  setRef: (node: ?HTMLDivElement) => void,
  text: ?string,
|}

type State = {|
  loading: boolean,
|}

export default class Failure extends React.PureComponent<Props, State> {
  state: State = { loading: false }

  handleReloadClick: () => void = () => {
    location.reload()
    this.setState({ loading: true })
  }

  actionButton: () => React.Node = () => (
    <Button
      label={this.props.backAction ? t("back") : t("reload")}
      loading={this.state.loading}
      onClick={this.props.backAction || this.handleReloadClick}
      type="ghost"
    />
  )

  render(): React.Element<"div"> {
    return (
      <div className={styles.failure} ref={this.props.setRef}>
        <div className={styles.message}>{this.props.text || t("message")}</div>
        {this.actionButton()}
      </div>
    )
  }
}
