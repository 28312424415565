/**
 * @flow
 */
import * as React from "react"
import { t as globalT } from "helpers/i18n"
import Loader from "components/Loader"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.modal.loading.${key}`, opts)

type Props = {|
  +setRef: (node: ?HTMLDivElement) => void,
|}

export default class Loading extends React.PureComponent<Props> {
  render(): React.Element<"div"> {
    return (
      <div className={styles.loading} ref={this.props.setRef}>
        <Loader color="primary" size="xl" />
        <div className={styles.message}>{t("message")}</div>
      </div>
    )
  }
}
