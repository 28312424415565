/**
 * @flow
 */
import * as React from "react"
import moment from "moment"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import { isGreaterThanTwoDays } from "helpers/time"
import { DateTimePicker } from "components/DatePicker"
import { NumberInput, type InputColor } from "components/Input"
import Notice from "components/Notice"
import ToggleStandard from "components/ToggleStandard"
import * as Routes from "helpers/routes"
import Loader from "components/Loader"
import type {
  DailyBreakdown,
  CurrentUser,
  LeavesListItem,
  PptSchedule,
  Time,
  LeaveUser,
  FillFromRosterStrategy,
  ShiftsListItem,
} from "time_off/Modal/types"
import BreakdownSection from "../BreakdownSection"
import Label from "../Label"
import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.leave.modal.times.${key}`, opts)

type RequiredProps = {|
  allDay: boolean,
  currentUser: CurrentUser,
  daily_breakdown: ?DailyBreakdown,
  days: ?string,
  endDate: moment,
  endTime: Time,
  fetchingDailyBreakdown: ?boolean,
  fetchingLeaveAppliesOnDates: boolean,
  fillFromRosterStrategy: FillFromRosterStrategy,
  initialDailyBreakdown?: DailyBreakdown,
  leaveAppliesOnDates: { [string]: boolean },
  leavesList?: Array<LeavesListItem>,
  leaveTypeName?: string,
  loading?: ?boolean,
  onAllDayChange: (allDay: boolean) => void,
  onEndDateChange: (endDate: moment) => void,
  onEndTimeChange: (endTime: Time) => void,
  onFillFromRosterStrategyChange: (strategy: FillFromRosterStrategy) => void,
  onHoursChange: (hours: string) => void,
  onSaveDailyBreakdown: ?(DailyBreakdown) => void,
  onStartDateChange: (startDate: moment) => void,
  onStartTimeChange: (startTime: Time) => void,
  shiftsList?: Array<ShiftsListItem>,
  startDate: moment,
  startTime: Time,
  viewInDays: boolean,
|}

type Props = {|
  ...RequiredProps,
  currentLeaveBalance?: number,
  fallbackLeaveTypeName?: ?string,
  finalLeaveBalance?: number,
  hideHours?: boolean,
  hours?: string,
  isLeaveAveragingLeaveType?: boolean,
  minDate?: moment,
  onShiftOverlapChange?: (shiftOverlap: boolean) => void,

  pendingPptAcceptanceDates?: { [date: string]: Array<PptSchedule> },
  repeatsForever?: boolean,
  userId?: ?string,
  users?: Array<LeaveUser>,
|}

type State = {
  endDateFocus: boolean,
  startDateFocus: boolean,
}

const DEFAULT_PROPS: $Diff<Props, RequiredProps> = {
  currentLeaveBalance: undefined,
  fallbackLeaveTypeName: undefined,
  finalLeaveBalance: undefined,
  hideHours: undefined,
  hours: undefined,
  isLeaveAveragingLeaveType: false,
  loading: false,
  minDate: undefined,
  onShiftOverlapChange: () => {},
  pendingPptAcceptanceDates: {},
  repeatsForever: undefined,
  userId: undefined,
  users: [],
}

export default class TimeSection extends React.PureComponent<Props, State> {
  static defaultProps: $Diff<Props, RequiredProps> = DEFAULT_PROPS

  state: State = {
    endDateFocus: false,
    startDateFocus: false,
  }
  managerMayOverrideWarning: () => null | React.Element<"span"> = () => {
    if (!this.props.currentUser.isManager) {
      return <span className={styles.hints}>{t("hours_hint")}</span>
    }
    if (this.props.userId !== this.props.currentUser.id) {
      return null
    }

    return this.props.currentUser.canApproveOwnLeaveRequest ? null : (
      <span className={styles.hints}>{t("hours_hint")}</span>
    )
  }

  handleHoursChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    this.props.onHoursChange(event.target.value)
  }

  /*  Only display the all_day toggle if its a leave request without a breakdown
      or is a single day leave request

      or if it is a consecutive 2 day request (overnight leave cases)
   */
  displayAllDay: () => React.Element<"div"> = () => (
    <div className={styles.allDay}>
      <label className={styles.toggleLabel} htmlFor="all-day-toggle">
        {t("all_day_title")}
      </label>
      <ToggleStandard
        applied={this.props.allDay}
        color="blue"
        id="all-day-toggle"
        locked={isGreaterThanTwoDays(this.props.startDate, this.props.endDate)}
        onClick={this.props.onAllDayChange}
      />
    </div>
  )

  hoursBoxColor: () => InputColor = () => {
    const possibleColors = []

    if (
      this.props.finalLeaveBalance != null &&
      this.props.finalLeaveBalance < 0 &&
      this.props.currentUser.id === this.props.userId &&
      !this.props.currentUser.canApproveOwnLeaveRequest
    ) {
      if (this.props.fallbackLeaveTypeName) {
        possibleColors.push("warning")
      } else {
        possibleColors.push("error")
      }
    }

    if (this.props.daily_breakdown?.some((shiftSummary) => shiftSummary.hours > 24)) {
      possibleColors.push("error")
    } else if (this.props.daily_breakdown?.some((shiftSummary) => shiftSummary.hours > 16)) {
      possibleColors.push("warning")
    }

    // Pick the highest severity colour
    if (possibleColors.includes("error")) {
      return "error"
    } else if (possibleColors.includes("warning")) {
      return "warning"
    } else {
      return "default"
    }
  }

  renderHoursInDayWarning: () => null | React.Node = () => {
    if (this.props.daily_breakdown?.some((shiftSummary) => shiftSummary.hours > 24)) {
      return (
        <div className={styles.leaveBalanceErrorSection}>
          <div className={styles.errorIcon}>
            <div className="mi mi-error-outline" />
          </div>
          <div className={styles.leaveBalanceError}>{t("more_than_24_hours_per_day_error")}</div>
        </div>
      )
    } else if (this.props.daily_breakdown?.some((shiftSummary) => shiftSummary.hours > 16)) {
      // Have arbitrarily decided upon 16hrs as an unreasonable amount of hours per day to warn on.
      // Requests that have this amount per day are likely a mistake.
      return (
        <div className={styles.fallbackWarningSection}>
          <div className={styles.warningIcon}>
            <div className="mi mi-error-outline" />
          </div>
          <div className={styles.fallbackWarning}>{t("more_than_16_hours_per_day_warning")}</div>
        </div>
      )
    }
    return null
  }

  renderLeaveBalanceWarning: () => null | React.Element<"div"> = () => {
    if (
      this.props.finalLeaveBalance == null ||
      Number.isNaN(this.props.finalLeaveBalance) ||
      this.props.finalLeaveBalance >= 0
    ) {
      // either no leave balance, or will be positive after leave
      return null
    }

    if (this.props.fallbackLeaveTypeName) {
      return (
        <div className={styles.fallbackWarningSection}>
          <div className={styles.warningIcon}>
            <div className="mi mi-error-outline" />
          </div>
          <div className={styles.fallbackWarning}>{t("fallback_warning")}</div>
        </div>
      )
    }

    let errorMessage
    if (this.props.currentUser.id === this.props.userId && !this.props.currentUser.canApproveOwnLeaveRequest) {
      if (this.props.currentLeaveBalance != null && this.props.currentLeaveBalance <= 0) {
        errorMessage = t("leave_balance_exhausted_error")
      } else {
        errorMessage = t("leave_balance_error", {
          balance_before: this.props.currentLeaveBalance,
          balance_after: this.props.finalLeaveBalance,
          hours: -this.props.finalLeaveBalance,
        })
      }
    } else {
      errorMessage = t("leave_balance_warning", { hours: -this.props.finalLeaveBalance })
    }

    return (
      <div className={styles.leaveBalanceErrorSection}>
        <div className={styles.errorIcon}>
          <div className="mi mi-error-outline" />
        </div>
        <div className={styles.leaveBalanceError}>{errorMessage}</div>
      </div>
    )
  }

  renderOverlappingShiftsWarning: () => React.Element<"div"> = () => {
    const { shiftsList = [], allDay, startTime, endTime, startDate, endDate } = this.props
    let overLappingShifts = shiftsList

    if (this.props.onShiftOverlapChange) {
      this.props.onShiftOverlapChange(!!overLappingShifts.length && !allDay)
    }

    const shouldFilterOverlappingShifts = overLappingShifts.length && !allDay

    if (shouldFilterOverlappingShifts) {
      overLappingShifts = shiftsList.filter((shift, i) => {
        const leaveStartDateAsMoment = moment({
          years: startDate.year(),
          months: startDate.month(),
          date: startDate.date(),
          hour: startTime.hour,
          minute: startTime.minute,
        })
        const leaveEndDateAsMoment = moment({
          years: endDate.year(),
          months: endDate.month(),
          date: endDate.date(),
          hour: endTime.hour,
          minute: endTime.minute,
        })

        const leaveStartsInsideShift = moment(leaveStartDateAsMoment).isBetween(shift.start, shift.end, undefined, "[)")
        const leaveEndsInsideShift = moment(leaveEndDateAsMoment).isBetween(shift.start, shift.end, undefined, "(]")
        const shiftStartsInsideLeave = moment(shift.start).isBetween(
          leaveStartDateAsMoment,
          leaveEndDateAsMoment,
          undefined,
          "[)"
        )
        const shiftEndsInsideLeave = moment(shift.end).isBetween(
          leaveStartDateAsMoment,
          leaveEndDateAsMoment,
          undefined,
          "(]"
        )

        return leaveStartsInsideShift || leaveEndsInsideShift || shiftStartsInsideLeave || shiftEndsInsideLeave
      })

      if (this.props.onShiftOverlapChange) {
        this.props.onShiftOverlapChange(!!overLappingShifts.length)
      }
    }

    const renderOverlapShiftsWarningBody = (shifts) => {
      const warningSection = shifts.map((shift) => {
        const { start, end, id, timesheet_id } = shift
        const shiftDates = `${moment(start).format("Do MMMM YYYY h:mma")} - ${moment(end).format("h:mma")}`
        return (
          <div className={styles.overlappingShiftRequest} key={id}>
            <div>
              <a href={Routes.timesheet_path(timesheet_id)} rel="noopener noreferrer" target="_blank">
                {shiftDates}
              </a>
            </div>
          </div>
        )
      })

      return (
        <Notice
          text={allDay ? "" : t("leave_request.shift_overlap_warning_body")}
          title={t("leave_request.shift_overlap_warning")}
          type={allDay ? "warning" : "danger"}
        >
          {warningSection}
        </Notice>
      )
    }

    if (!overLappingShifts.length) {
      return <div />
    }

    return (
      <div className={styles.overlappingShiftsWarningSection}>
        <div className={styles.warningCaption}>{renderOverlapShiftsWarningBody(overLappingShifts)}</div>
      </div>
    )
  }

  renderOverlappingRequestsWarning: () => React.Element<"div"> = () => {
    const { leavesList = [], leaveTypeName, currentUser } = this.props
    const approvedLeaves = leavesList.filter((leave) => leave.status === "approved")
    const pendingLeaves = leavesList.filter((leave) => leave.status === "pending")
    const [conflictingRequests, nonConflictingRequests] = _.partition(
      approvedLeaves,
      (leave) => leave.leaveType === leaveTypeName
    )

    const renderOverlapWarningBody = (leaves, isError) => {
      const warningSection = leaves.map((leave) => {
        const { finish, id, leaveType, reason, start, status } = leave
        const dateRange = `${moment(start).format("Do MMMM YYYY")} - ${moment(finish).format("Do MMMM YYYY")}`
        const requestDatesCaption = `${t("leave_request.request_dates_label")}: ${dateRange}`
        const leaveTypeCaption = `${t("leave_request.leave_type_label")}: ${leaveType}`
        const statusCaption = `${t("leave_request.status_label")}: ${status.charAt(0).toUpperCase() + status.slice(1)}`

        return (
          <div className={styles.overlappingLeaveRequest} key={id}>
            <div>{statusCaption}</div>
            <div>{requestDatesCaption}</div>
            <div>{leaveTypeCaption}</div>
            {reason != null ? (
              <div>{`${t("leave_request.reason_label")}: ${reason}`}</div>
            ) : (
              <div>
                {`${t("leave_request.reason_label")}: `}
                <i>{t("leave_request.no_reason_placeholder")}</i>
              </div>
            )}
          </div>
        )
      })

      if (leaves.length) {
        return (
          <Notice
            title={isError ? t("leave_request.overlap_same_leave_type_error") : t("leave_request.overlap_warning")}
            type={isError ? "danger" : "warning"}
          >
            {warningSection}
            {isError && !currentUser.isManager && <em>{t("leave_request.approved_overlap_instruction")}</em>}
          </Notice>
        )
      } else {
        return null
      }
    }

    return (
      <div className={styles.overlappingRequestsWarningSection}>
        <div className={styles.warningCaption}>
          {renderOverlapWarningBody(conflictingRequests, true)}
          {renderOverlapWarningBody(pendingLeaves.concat(nonConflictingRequests), false)}
        </div>
      </div>
    )
  }

  render(): React.Element<"div"> {
    return (
      <div className={styles.times}>
        {!this.props.viewInDays && this.displayAllDay()}
        <Label text={t("dates_title")} />
        <div className={styles.picker}>
          <DateTimePicker
            allDay={this.props.allDay}
            date={this.props.startDate}
            disabled={this.props.loading || false}
            minDate={this.props.minDate}
            onDateChange={this.props.onStartDateChange}
            onTimeChange={this.props.onStartTimeChange}
            time={this.props.startTime}
            timeFormat={this.props.currentUser.timeFormat}
          />
        </div>
        <div className={styles.picker}>
          <DateTimePicker
            allDay={this.props.allDay}
            date={this.props.endDate}
            disabled={this.props.loading || false}
            minDate={this.props.minDate}
            onDateChange={this.props.onEndDateChange}
            onTimeChange={this.props.onEndTimeChange}
            repeatsForever={this.props.repeatsForever}
            time={this.props.endTime}
            timeFormat={this.props.currentUser.timeFormat}
          />
        </div>
        {this.props.leavesList != null && this.props.leavesList.length > 0 && this.renderOverlappingRequestsWarning()}
        {this.renderOverlappingShiftsWarning()}
        {this.props.isLeaveAveragingLeaveType && (
          <Notice title={t("leave_averaging_title")} type="info">
            {t("leave_averaging_body_one")}
            <br />
            {t("leave_averaging_body_two")}
          </Notice>
        )}
        {this.props.daily_breakdown != null &&
          this.props.fetchingDailyBreakdown != null &&
          this.props.onSaveDailyBreakdown != null &&
          !this.props.viewInDays && (
            <BreakdownSection
              currentUser={this.props.currentUser}
              daily_breakdown={this.props.daily_breakdown}
              fetchingDailyBreakdown={this.props.fetchingDailyBreakdown}
              fetchingLeaveAppliesOnDates={this.props.fetchingLeaveAppliesOnDates}
              fillFromRosterStrategy={this.props.fillFromRosterStrategy}
              initialDailyBreakdown={this.props.initialDailyBreakdown}
              leaveAppliesOnDates={this.props.leaveAppliesOnDates}
              onFillFromRosterStrategyChange={this.props.onFillFromRosterStrategyChange}
              onSaveDailyBreakdown={this.props.onSaveDailyBreakdown}
              pendingPptAcceptanceDates={this.props.pendingPptAcceptanceDates || {}}
              timeFormat={this.props.currentUser.timeFormat}
              userId={this.props.userId || ""}
              users={this.props.users || []}
            />
          )}
        {(() => {
          if (!this.props.hideHours) {
            return (
              <div className={styles.hoursSection}>
                <div>
                  {this.props.viewInDays ? (
                    <Label required text={t("days_title")} />
                  ) : (
                    <div className={styles.hoursTitleWrapper}>
                      <Label required text={t("hours_title")} />
                      {this.managerMayOverrideWarning()}
                    </div>
                  )}
                  <div className={styles.hoursInputWrapper}>
                    <div className={styles.hours}>
                      <NumberInput
                        color={this.hoursBoxColor()}
                        disabled={this.props.viewInDays}
                        onChange={this.handleHoursChange}
                        size="l"
                        value={this.props.viewInDays ? this.props.days : this.props.hours}
                      />
                      {/* We render this loader for non managers because non managers wont see the breakdown loading. */}
                    </div>
                    {!this.props.currentUser.isManager && this.props.fetchingDailyBreakdown && (
                      <Loader color="primary" size="l" />
                    )}
                  </div>
                </div>
                {this.renderLeaveBalanceWarning()}
                {this.renderHoursInDayWarning()}
              </div>
            )
          }
        })()}
      </div>
    )
  }
}
