/**
 * @flow
 */
import moment from "moment"
import Big from "big.js"
import _ from "lodash"
import { t as globalT } from "helpers/i18n"
import type { DailyBreakdown, ShiftSummary } from "../../types"
import { TIME_FORMATS, FORMAT_12, FORMAT_24 } from "./constants"

const getTimeDiff = (otherTime: string, newValue: string | moment): moment$MomentDuration => {
  if (moment.isMoment(newValue)) {
    // If it's a moment, it means it has been validly formatted.
    return moment.duration(moment(otherTime, TIME_FORMATS).diff(newValue))
  }
  return moment.duration.invalid()
}

const getTimeDiffOvernight = (startTime: moment, endTime: moment): moment$MomentDuration =>
  moment.duration(endTime.clone().add(1, "day").diff(startTime))

const formatTime = (timeString: string): string => {
  const timePreference = window.time_formatter.twelve_hour_time() ? FORMAT_12 : FORMAT_24
  if (timeString) {
    // This is to handle the two possible time formats:
    // "2000-01-01T09:00:00" Ruby generated breakdown time
    // "9:00 am" User edited time
    const time = timeString.match(/\d{4}-[01]\d-[0-3]\d/)
      ? window.time_formatter.roster_validation_field(timeString)
      : moment(timeString, TIME_FORMATS).format(timePreference.toString())

    return moment(time, timePreference.toString()).isValid()
      ? time
      : globalT("js.settings.roster_validation.invalid_time")
  } else {
    return ""
  }
}

const shortenFloat = (number: number | string): number => {
  number = number.toString()
  return parseFloat(number.slice(0, number.indexOf(".") + 5))
}

const getTotalHours = (daily_breakdown: DailyBreakdown): number | string => {
  if (!_.isEmpty(daily_breakdown)) {
    const hoursArray = daily_breakdown.map((shift_summary: ShiftSummary) =>
      // Even though `hours` is typed as a string, it is possible for it to be a string prior
      // to validation since it can be edited from a text input
      // $FlowFixMe
      shift_summary.hours != null && shift_summary.hours !== "" ? shift_summary.hours : 0
    )
    // $FlowFixMe Big isnt typed
    return Number(hoursArray.reduce((hours: Big, currVal) => hours.add(currVal), new Big(0))).toPrecision(4)
  }
  return 0
}

// For each autofill method ("roster", etc), there can be a corresponding "dirty" version, which means it has been filled
// from that place, but it has been manually modified by a manager. This method changes the fill method to dirty for when
// a manager manually edits the shift summary.
export const dirtifyFilledFrom = (filled_from: string): string =>
  filled_from && ["regular_hours", "roster", "unpublished_roster"].includes(filled_from)
    ? `${filled_from}_dirty`
    : filled_from

// This will remove the dirty modifier from the filled_from method, for when the manager reverts their manual edits to be
// exactly the same as how it was originally.
export const cleanFilledFrom = (filled_from: string): string =>
  filled_from && ["regular_hours_dirty", "roster_dirty", "unpublished_roster_dirty"].includes(filled_from)
    ? filled_from.replace("_dirty", "")
    : filled_from

export default {
  getTimeDiff,
  getTimeDiffOvernight,
  formatTime,
  shortenFloat,
  getTotalHours,
  dirtifyFilledFrom,
  cleanFilledFrom,
}
