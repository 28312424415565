/**
 * @flow
 */
import moment from "moment"
import { omit, isEqual } from "lodash"
import type { ShiftSummary, FieldTypes } from "../../types"
import { FINISH_TIME, START_TIME, INVALID_TIME, HOURS, ALL_DAY } from "./constants"

export const validateBreakdownRow = (
  dayBreakdown: ShiftSummary,
  id: string
): { [id: string]: FieldTypes } | $Shape<{||}> => {
  // Can't have one blank time field
  const startIsPresent = dayBreakdown.start_time != null && dayBreakdown.start_time !== ""
  const finishIsPresent = dayBreakdown.finish_time != null && dayBreakdown.finish_time !== ""
  if (startIsPresent && !finishIsPresent) {
    return { [id]: FINISH_TIME }
  }
  if (finishIsPresent && !startIsPresent) {
    return { [id]: START_TIME }
  }

  // Time fields can't match unless they are 0
  const bothTimesPresent = startIsPresent && finishIsPresent
  if (!dayBreakdown.all_day && bothTimesPresent && dayBreakdown.start_time === dayBreakdown.finish_time) {
    return { [id]: HOURS }
  }

  // Must be a valid time
  if (dayBreakdown.start_time === INVALID_TIME) {
    return { [id]: START_TIME }
  }
  if (dayBreakdown.finish_time === INVALID_TIME) {
    return { [id]: FINISH_TIME }
  }

  // Hours cannot be > 24
  if (parseInt(dayBreakdown.hours) > 24) {
    return { [id]: HOURS }
  }

  return ({}: $Shape<{||}>)
}

export const hasBeenModifiedFrom = (newSummary: ShiftSummary, initialSummary: ShiftSummary): boolean => {
  let startFinishTimesEqual = true

  if (!newSummary[ALL_DAY] && !initialSummary[ALL_DAY]) {
    const startTime = moment(newSummary[START_TIME])
    const finishTime = moment(newSummary[FINISH_TIME])
    const initialStartTime = moment(initialSummary[START_TIME])
    const initialFinishTime = moment(initialSummary[FINISH_TIME])

    startFinishTimesEqual =
      startTime.hours() === initialStartTime.hours() &&
      startTime.minutes() === initialStartTime.minutes() &&
      finishTime.hours() === initialFinishTime.hours() &&
      finishTime.minutes() === initialFinishTime.minutes()
  }

  return (
    startFinishTimesEqual &&
    isEqual(
      omit(newSummary, "filled_from", START_TIME, FINISH_TIME),
      omit(initialSummary, "filled_from", START_TIME, FINISH_TIME)
    )
  )
}

export default { validateBreakdownRow, hasBeenModifiedFrom }
