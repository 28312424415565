// @flow
import moment from "moment"
import * as Model from "platform/models/model"
import { getAwardColor } from "helpers/award"

export type DefaultValidationSettings = {|
  [validation_type: string]: DefaultValidationSettingObject,
|}

export type DefaultValidationSettingObject = {|
  block_roster_publishing: boolean,
  enabled: boolean,
  id: number,
|}

export type DefaultValidationFieldSettings = {|
  ...ValidationFieldsType,
  block_roster_publishing: boolean,
|}

export type CustomValidationSettings = {
  [id: string]: CustomValidationSettingObject,
}

export type CustomValidationSettingObject = {
  block_roster_publishing: boolean,
  date_filters: {
    date_ranges: {
      [id: string]: {
        endDate: ?string, // "2019-08-04T13:59:59.999Z"
        startDate: ?string, // "2019-08-04T13:59:59.999Z"
      },
    },
    selected_days: Array<number>, // weekdays
  },
  enabled: boolean,
  fields: ValidationFieldsType,
  filters: {
    location_ids: Array<number>,
  },
  id: number,
  name: ?string,
  overtime_conditions: Array<string>,
  validate_ot_shifts: boolean,
}

type ValidationFieldsType = {|
  earliest_start?: ?number, // mins - earliest_start
  latest_finish?: ?number, // mins - latest_finish
  max_hours_in_day?: ?number, // hrs - max_hours_in_day
  max_hours_in_week?: ?number, // hrs - max_hours_in_week
  max_hours_worked_including_breaks?: ?number, // hrs - max_hours_worked_including_breaks
  max_length?: ?string | number, // hrs - max_length
  max_shifts_in_day?: ?number, // shifts - max_shifts_in_day
  max_shifts_in_week?: ?number, // shifts - max_shifts_in_week
  max_weekly_hours_start_day?: ?string, // shifts - max_weekly_hours_start_day
  min_gap?: ?string, // hrs - min_gap
  min_length?: ?string, // hrs - min_length
|}

export type OrganisationType = {
  country: ?string,
  enable_predictive_headcounts: boolean,
  forecasting_strategy: string,
  id: number,
  one_click_schedule: boolean,
}

export type PublicHolidayType = {
  all: Array<string>,
  [location_id: string]: Array<string>,
}

export type UserCustomValidationMap = {
  [user_id: string]: Array<number>, // custom validation settings ids
}

export type ShiftSlot = {|
  colour: ?string,
  ending_after: ?number,
  ending_before: ?number,
  id: number,
  name: string,
  sort_order: ?number,
  starting_after: ?number,
  starting_before: ?number,
|}

export type RosterTemplatePermissions = {|
  create: boolean,
  delete: boolean,
  read: boolean,
  update: boolean,
|}

export type Schema = {|
  allowances: Array<Allowance>,
  awards: Array<Award>,
  business_hours: Array<BusinessHours>,
  current_user_id: number,
  custom_validation_settings: CustomValidationSettings,
  default_validation_field_settings: DefaultValidationFieldSettings,
  default_validation_settings: DefaultValidationSettings,
  deps_users_can_work_in: Array<UserToDepartmentMap>,
  employee_custom_validations_map: UserCustomValidationMap,
  enable_full_location_rosters: boolean,
  enable_roster_validation: boolean,
  enable_secondary_departments: boolean,
  enable_shift_acceptance: boolean,
  enable_staff_type_filter: boolean,
  enable_task_based_rostering: boolean,
  labour_budget_enabled: boolean,
  managed_team_ids: Array<number>,
  organisation: OrganisationType,
  pay_period_length: number,
  permissions: {|
    can_approve_leave: boolean,
    can_approve_own_leave: boolean,
    can_create_leave: boolean,
    can_create_schedule: boolean,
    can_create_timesheets: boolean,
    can_import_ratios: boolean,
    can_lock_rosters: boolean,
    can_publish_rosters: boolean,
    can_update_leave: boolean,
    can_update_open_hour_periods: boolean,
    can_update_projections_table_configuration: boolean,
    can_update_schedule: boolean,
    can_update_shift_proposal: boolean,
    can_view_projections_table_configuration: boolean,
    roster_templates: RosterTemplatePermissions,
  |},
  platform_model: {
    location: Model.Schema,
    schedule: Model.Schema,
    user: Model.Schema,
  },
  public_holidays: PublicHolidayType,
  publishable_team_ids: Array<number>,
  recent_pay_period_start: moment,
  roster_week_start_day: number,
  shift_claiming_enabled: boolean,
  shift_slots: Array<ShiftSlot>,
  shift_swapping_enabled: boolean,
  show_weekends: boolean,
  sms_enabled: boolean,
  using_ppt_acceptance: boolean,
  visible_user_ids: Array<number>,
  week_start_day: number,
|}

export type BusinessHours = {|
  finish: number,
  id: number,
  location_id: number,
  start: number,
  weekday: number,
|}

export type SpanOfHours = {|
  finish: number,
  start: number,
|}

// Used to keep track of which employees can work in which departments
export type UserToDepartmentMap = {
  team_id: number,
  user_id: number,
}

export type AwardRuby = {
  id: number,
  leave: {
    leave_enabled: string,
  },
  name: string,
  rates: {
    hourly_rate?: string,
    multiplier?: string,
    ordinary_hours?: string,
  },
  rules: {
    min_length?: ?number,
  },
}

export type BasicAwardData = {
  active_rules: Array<string>,
  id: number,
  is_ord_hours: boolean,
  name: string,
}

export type Award = {
  active_rules: Array<string>,
  color: string,
  has_min_length: boolean,
  id: number,
  is_leave: boolean,
  is_ord_hours: boolean,
  multiplier: number,
  name: string,
  rates: {|
    hourly_rate: ?number,
    multiplier: ?number,
    ordinary_hours: ?number,
  |},
}

export const transformAwards = (rubyAwards: Array<AwardRuby>): Array<Award> => {
  const awards = rubyAwards.map((award) => ({
    id: award.id,
    name: award.name,
    rates: {
      hourly_rate: award.rates?.hourly_rate ? Number(award.rates?.hourly_rate) : null,
      ordinary_hours: award.rates?.ordinary_hours ? Number(award.rates?.ordinary_hours) || null : null,
      multiplier: award.rates?.multiplier ? Number(award.rates?.multiplier) : null,
    },
    multiplier: award.rates?.multiplier ? Number(award.rates?.multiplier) : 1,
    is_ord_hours: award.rates?.ordinary_hours === "false" ? false : Boolean(award.rates?.ordinary_hours),
    has_min_length: Boolean(award.rules?.min_length),
    is_leave: award.leave?.leave_enabled === "true",
    color: "",
    active_rules: Object.keys(award.rules).filter(
      (condition) => !["0.0", "0", "false", ""].includes(award.rules[condition])
    ),
  }))
  return awards.map((award) => ({
    ...award,
    color: getAwardColor(award),
  }))
}

export const transformShiftSlots = (shift_slots: Array<ShiftSlot>): Array<ShiftSlot> =>
  shift_slots.map((ss) => {
    const min_starting_before = ss.starting_after || 0
    const starting_before =
      ss.starting_before == null
        ? null
        : ss.starting_before < min_starting_before
        ? ss.starting_before + 24
        : ss.starting_before
    const min_ending_after = ss.starting_after || 0
    const ending_after =
      ss.ending_after == null ? null : ss.ending_after < min_ending_after ? ss.ending_after + 24 : ss.ending_after
    const min_ending_before = ending_after || 0
    const ending_before =
      ss.ending_before == null ? null : ss.ending_before < min_ending_before ? ss.ending_before + 24 : ss.ending_before
    return {
      ending_after: ending_after,
      ending_before: ending_before,
      id: ss.id,
      name: ss.name,
      sort_order: ss.sort_order,
      starting_after: ss.starting_after,
      starting_before: starting_before,
      colour: ss.colour,
    }
  })

export type AllowanceRuby = {
  id: number,
  name: string,
}

export type Allowance = {|
  id: number,
  name: string,
|}

export const transformAllowances = (rubyAllowances: Array<AllowanceRuby>): Array<Allowance> =>
  rubyAllowances.map((allowance) => ({
    id: allowance.id,
    name: allowance.name,
  }))
